import React from 'react';
import { Form } from 'semantic-ui-react';
import {
  ListFactorys,
  SearchPanel,
} from '../../views';


import './monitor-factory.css'

const MonitorFactory = props => {

  const { factoryTypes, areas, onChangeArea, onChangeType,
    onSearchChange, visibleItems, setFactory, selectedFactory
  } = props;

  const createList = (defaultValue, list) => (
    [
      { key: -1, text: defaultValue, value: -1 },
      ...list.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id
      }))
    ]
    
  )

  const typeList = createList('Все предприятия', factoryTypes);
  const areaList = createList('Все регионы', areas);

  return (
    <div className='monitor-factory'>
      <Form >
        <Form.Field className='header-select'
          label='Выберите предприятие' />
        <Form.Select
          label='Регион'
          placeholder='Все регионы'
          options={areaList}
          onChange={onChangeArea}
        />
        <Form.Select
          label='Тип предприятия'
          placeholder='Все предприятия'
          options={typeList}
          onChange={onChangeType}
        />

        <SearchPanel className='factory-search-panel'
          onSearchChange={onSearchChange} />
        <ListFactorys
          items={visibleItems}
          onSelected={setFactory}
          selectedItem={selectedFactory} />
      </Form>
    </div>
  )
}


export default MonitorFactory;
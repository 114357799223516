import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import './info-versions.css';

import { DataVisual } from '../index';
import Spinner from '../spinner';

const visual = data => {
    return (
        <React.Fragment>
            {data.map(({ softwareName, description, version }) => {
                return (
                    <div key={softwareName} className='software-info'>
                        <p className={`software-name`}>{description}</p>
                        <p className='software-version'>{version}</p>
                    </div>
                )
            })
            }
        </React.Fragment>
    );
};

const Versions = (versions) => {
    if (versions === undefined) {
        return <Spinner />
    }
    return (
        <div>
            {versions && Array.isArray(versions) && Object.keys(versions).map((uspd) => {
                return (
                    <Segment.Group key={uspd}>
                        <Segment className='software'>
                            <Header as='h3'>УСПД: {versions[uspd].uspdNumber}</Header>
                            <DataVisual visual={visual} data={versions[uspd].softwares} />
                        </Segment>
                    </Segment.Group>
                )
            })
            }
        </div>)
}

export default Versions;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReportSorting } from '../views';

import {
  setReportParams,
} from '../../redux/actions';

import { produceSortOptions, violationSortOptions } from '../../utils/common';

import * as reportReducer from '../../redux/reducers/reduce-report';

class ReportSortContainer extends Component {

  changeReportParam = (param) => {
    const { setReportParams, type } = this.props;
    setReportParams({
      typeReport: type,
      settings: { sortingBy: param }
    })
  }

  render() {
    const { sortingBy, type } = this.props;
    const sortOptions = type ==='violation' ? violationSortOptions : produceSortOptions;  
    return (
      <ReportSorting
        sortingBy={sortingBy}
        handleChange={this.changeReportParam}
        sortOptions={sortOptions}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sortingBy: reportReducer.getSortingType(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setReportParams: params => dispatch(setReportParams(params)),
  };
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportSortContainer);
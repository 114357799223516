import React from 'react';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import logo from '../../../images/favicon.ico';
import './login-form.css';

const LoginForm = ({ message, onLogin, onChange, error }) => (
    <Grid className={`login-form ${error ? 'error' : ''}`}>
        <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' textAlign='center'>
                <Image src={logo} />
                <p>{document.title}</p>
            </Header>
            <Segment stacked>
                <Form size='large' onSubmit={onLogin}>
                    <Form.Input
                        fluid
                        icon='user'
                        iconPosition='left'
                        placeholder='Имя пользователя'
                        name='username'
                        onChange={onChange}
                    />
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Пароль'
                        type='password'
                        name='password'
                        onChange={onChange}
                    />
                    <Button fluid size='large' loading={message ? false : true}>
                        {message}
                    </Button>
                </Form>
            </Segment>
        </Grid.Column>
    </Grid>
);

export default LoginForm;

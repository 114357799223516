import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinesList } from '../views';

import {
  setSelectedLine,
} from '../../redux/actions';

import * as linesReducer from '../../redux/reducers/reduce-lines';

class LinesContainer extends Component {

  handleClick = (lineId) => (e, titleProps) => {
    const { selectedLine, setSelectedLine } = this.props;
    const newIndex = selectedLine === lineId ? -1 : lineId

    setSelectedLine(newIndex);
  }

  render() {
    const { lines, uspdNumber, selectedLine } = this.props;
    return (
      <LinesList
        lines={lines}
        uspdNumber={uspdNumber}
        selectedLine={selectedLine}
        handleClick={this.handleClick}
      />
    )
  }
}

const mapStateToProps = state => {
  const { selectedLine } = linesReducer.getDetails(state);
  return {
    selectedLine,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedLine: id => dispatch(setSelectedLine(id)),
  }
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LinesContainer);
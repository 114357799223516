import React from 'react';
import { Menu, Table } from 'semantic-ui-react';

import './accum-data.css'

const viewValue = value =>
  value !== undefined ? value.toFixed(2) : 'NaN';

const viewValueInt = value =>
  value !== undefined ? value.toFixed(0) : 'NaN';

const AccumData = ({ header, reportLine }) => (
  <div>
    <h5>{header}</h5>
    <Table unstackable celled selectable className='accum-table-data'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Объем</Table.HeaderCell>
          <Table.HeaderCell>Безводный объем</Table.HeaderCell>
          <Table.HeaderCell>Бутылок</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>{viewValue(reportLine.alkoVolume)}</Table.Cell>
          <Table.Cell>{viewValue(reportLine.alkoVolume100)}</Table.Cell>
          <Table.Cell>{viewValueInt(reportLine.bottlesCounts)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </div>

  //   <Menu className='result' borderless >
  //   <Menu.Item>
  //     <h5>{header}</h5>
  //   </Menu.Item>
  //   <Menu.Item>{`Объем: ${viewValue(
  //     reportLine.alkoVolume
  //   )}`}</Menu.Item>
  //   <Menu.Item>{`Безводный объем: ${viewValue(
  //     reportLine.alkoVolume100
  //   )}`}</Menu.Item>
  //   <Menu.Item>{`Количество бутылок: ${viewValueInt(
  //     reportLine.bottlesCounts
  //   )}`}</Menu.Item>
  // </Menu>

  // <Menu className='result' borderless >
  //   <Menu.Item>
  //     <h5>{header}</h5>
  //   </Menu.Item>
  //   <Menu.Item>{`Объем: ${viewValue(
  //     reportLine.alkoVolume
  //   )}`}</Menu.Item>
  //   <Menu.Item>{`Безводный объем: ${viewValue(
  //     reportLine.alkoVolume100
  //   )}`}</Menu.Item>
  //   <Menu.Item>{`Количество бутылок: ${viewValueInt(
  //     reportLine.bottlesCounts
  //   )}`}</Menu.Item>
  // </Menu>
);
export default AccumData;
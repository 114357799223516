import React, { Component } from 'react';
import { connect } from 'react-redux';

import { compose } from '../../utils';
import withAPI from '../hoc/with-api';

import * as factoryReducer from '../../redux/reducers/reduce-factorys';
import * as uspdReportReducer from '../../redux/reducers/reduce-uspd-report';
import * as linesReducer from '../../redux/reducers/reduce-lines';

import { setParam, fetchLines, fetchReportUspd, clearReportUspd } from '../../redux/actions';
import { UspdReportSettings } from '../views';


class UspdReportSettingsContainer extends Component {

  componentDidUpdate(prevProps) {
    const { selectedFactory, fetchLines } = this.props;

    if (selectedFactory !== prevProps.selectedFactory) {
      fetchLines(selectedFactory);
    }
  }

  clearSettings = () => this.props.clearReportUspd();

  getData = () => {
    const { numberUspd, regimes, from, to, fetchReportUspd, setReportParams, infoSelectedFactory } = this.props;

    setReportParams({ reportFactory: infoSelectedFactory.factoryName })
    fetchReportUspd(numberUspd, from, to, regimes)
  };


  funcFrom = (day) => {
    const { setReportParams } = this.props;
    setReportParams({ from: day })
  }

  funcTo = (day) => {
    const { setReportParams } = this.props;
    setReportParams({ to: day })
  }

  isDisabled = () => {
    const { regimes, numberUspd } = this.props;
    return regimes.length === 0 || numberUspd === -1;
  };

  onChangeUspd = (value) => this.props.setReportSelectUspd(value);
  setParams = newList => this.props.setReportRegimes(newList);

  render() {
    const { lines, numberUspd, regimes, data, from, to } = this.props;

    return (
      <UspdReportSettings
        lines={lines}
        numberUspd={numberUspd}
        regimes={regimes}
        onChangeUspd={this.onChangeUspd}
        setParams={this.setParams}
        funcFrom={this.funcFrom}
        funcTo={this.funcTo}
        from={from}
        to={to}
        getData={this.getData}
        loadDisabled={data === null || data === undefined}
        clearSettings={this.clearSettings}
      />
    )
  }
}

const mapStateToProps = state => {
  const { from, to } = uspdReportReducer.getInterval(state);
  return {
    from, to,
    numberUspd: uspdReportReducer.getSelectedUspd(state),
    regimes: uspdReportReducer.getRegimes(state),
    lines: linesReducer.getLines(state),

    selectedFactory: factoryReducer.getSelected(state),
    infoSelectedFactory: factoryReducer.getInfoSelected(state),

    data: uspdReportReducer.getData(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    setReportSelectUspd: uspdNumber => dispatch(setParam({ selectedUspd: uspdNumber })),
    setReportRegimes: regimes => dispatch(setParam({ regimes: regimes })),
    setReportParams: param => dispatch(setParam(param)),
    clearReportUspd: _ => dispatch(clearReportUspd()),

    fetchReportUspd: (uspdNumber, from, to, regimes) =>
      fetchReportUspd(uspdNumber, from, to, regimes, api, dispatch),
    fetchLines: idFactory => dispatch(fetchLines(idFactory, api))
  }
}

export default compose(
  withAPI(),
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(UspdReportSettingsContainer);
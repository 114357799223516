import moment from 'moment';

import {
  SET_PARAM,
  SET_REPORT_USPD,
  CLEAR_REPORT_USPD,
} from '../actions';

import { reduceAdd } from './reducers';

const initialState = {
  selectedUspd: -1,
  regimes: [],
  data: undefined,
  loading: false,

  day: moment().toDate(),
  from: moment().subtract(7, 'days').toDate(),
  to: moment().toDate(),

  reportUspd: null,
  reportRegimes: [],
  reportFrom: null,
  reportTo: null,
  reportFactory: null,
}

export const uspdReport = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARAM:
      return reduceAdd(state, action.payload);
    case SET_REPORT_USPD:
      return {
        ...state,
        reportUspd: state.selectedUspd,
        reportRegimes: state.regimes,
        reportFrom: state.from,
        reportTo: state.to,

        data: action.payload,
        loading: false,
      }
    case CLEAR_REPORT_USPD:
      state = initialState;
      return state;

    default:
      return state;
  };
}

export const getSelectedUspd = ({ uspdReport }) => {
  return uspdReport.selectedUspd;
}

export const getInterval = ({ uspdReport }) => {
  return {
    from: uspdReport.from,
    to: uspdReport.to
  }
}

export const getRegimes = ({ uspdReport }) => {
  return uspdReport.regimes;
}

export const getData = ({ uspdReport }) => {
  return uspdReport.data;
}

export const getLoading = ({ uspdReport }) => {
  return uspdReport.loading;
}

export const getReportUspd = ({ uspdReport }) => {
  return uspdReport.reportUspd;
}

export const getReportInterval = ({ uspdReport }) => {
  return {
    reportFrom: uspdReport.reportFrom,
    reportTo: uspdReport.reportTo
  }
}

export const getReportRegimes = ({ uspdReport }) => {
  return uspdReport.reportRegimes;
}

export const getReportFactory = ({ uspdReport }) => {
  return uspdReport.reportFactory;
}
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Modal, Button } from 'semantic-ui-react';

import { LoginForm } from '../../views';

import { withAPI } from '../../hoc';

import './login-page.css';

const LoginMessage = 'Войти';
const LoadingMessage = undefined;
const EmptyMessage = 'Заполните все поля';

const errorAnswer = {
    0: 'Добро пожаловать',
    1: 'Не верный логин или пароль',
    2: 'Аккаунт занят',
    3: 'Ошибка'
};

class LoginPage extends Component {
    state = {
        username: '',
        password: '',
        message: LoginMessage,
        open: false
    };

    close = () => this.setState({ message: LoginMessage, open: false });

    login = () => {
        const { username, password, open } = this.state;

        if (username === '' || password === '') {
            this.setState({ message: EmptyMessage });
            return;
        }

        this.setState({ message: LoadingMessage });
        const { Login } = this.props.api;

        Login({ username, password, flag: open.toString() }).then(
            ({ error }) => {
                this.setState({ message: errorAnswer[error] });
                if (error === 2) this.setState({ open: true });
            }
        );
    };
    handleChange = (e, { name, value }) =>
        this.setState({ message: LoginMessage, [name]: value });

    componentDidMount() {
        const { isAuth, Logout } = this.props.api;
        if (isAuth()) {
            Logout();
        }
    }

    render() {
        const { open, message } = this.state;

        if (this.props.api.isAuth()) {
            return <Redirect to='/' />;
        }

        return (
            <div>
                <LoginForm
                    onChange={this.handleChange}
                    onLogin={this.login}
                    error={message && message !== LoginMessage}
                    message={message}
                />
                <Modal className='modal'
                    dimmer='blurring'
                    basic
                    size='mini'
                    open={open}
                    centered={false}
                >
                    <Modal.Header>Учетная запись занята</Modal.Header>
                    <Modal.Content>
                        <p>Все равно зайти?</p>
                    </Modal.Content>
                    <Modal.Actions className='actions'>
                        <Button onClick={this.close} negative content='Нет' />
                        <Button
                            onClick={() => {
                                this.close();
                                this.login();
                            }}
                            positive
                            content='Да'
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default withAPI()(LoginPage);

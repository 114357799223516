import {
  SET_AREAS,
  SET_SELECTED_AREA,
} from '../actions';

const initialState = {
  areas: [],
  selectedArea: -1,
}

export const areas = (state = initialState, action) => {
  switch (action.type) {
    case SET_AREAS:
      return {
        ...state,
        areas: action.payload,
        selectedArea: null
      }
    case SET_SELECTED_AREA:
      return {
        ...state,
        selectedArea: action.payload,
      }
    default:
      return state;
  };
};

export const getAreas = ({ areas }) => {
  return areas.areas;
}

export const getSelectedArea = ({ areas }) => {
  return areas.selectedArea;
}

export const getCenter = ({ areas }) => {
  let center = { lat: 41.567313, lng: 62.850154 };
  if (areas.selectedArea > -1
    && Array.isArray(areas.areas)
    && areas.areas.map((item) => {
      if (item.id === areas.selectedArea) {
        center.lat = item.latitude;
        center.lng = item.longitude
      }
    }))
    return center;
}

import {
  SET_STORE_PARAMS,
  CLEAR_STORE_PARAMS,
} from '../actions';

import { reduceAdd } from './reducers';

const initialState = {
  isVisibleMap: false,
  isLogout: false,
  // activeLine: {
  //   pointOfControl: null,
  //   uspdNumber: null
  // },
  // day: moment().toDate(),
  // from: moment().subtract(7, 'days').toDate(),
  // to: moment().toDate(),
}

export const params = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_PARAMS:
      return reduceAdd(state, action.payload);
    case CLEAR_STORE_PARAMS:
      state = initialState
      return state

    default:
      return state;
  };
};

export const getStatusMap = ({ params }) => {
  return params.isVisibleMap;
}

export const getIsLogout = ({ params }) => {
  return params.isLogout;
}
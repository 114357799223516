import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import './page-menu-current.css';

const PageMenuCurrentMobile = props => {
  const { activeItem, children: panes, onClickItem } = props;

  const newPanes = Object.keys(panes).filter(key => panes[key].mobile)
  const options = newPanes.map(key => ({
      key: key,
      text: panes[key].label,
      value: key
  }))

  return (
    <div className='content-mobile'>
      {/* <Menu color='blue'> */}
        <Dropdown
        floating
          text={panes[activeItem] ? panes[activeItem].label : ""}
          options={options}
          onChange={(e, { value }) => onClickItem(value)()} />
      {/* </Menu> */}
    </div>
  );
};

export default PageMenuCurrentMobile;

import React from 'react';
import { Card, Image } from 'semantic-ui-react';

import './employee-data.css'

const EmployeeData = ({ employee }) => {
  return (
    <Card className='employee-data-visual'>
      <Card.Content>
        <Image
          floated='left'
          size='small'
          src={"data:image/png;base64," + employee.photo}
        />
        <Card.Header>{employee.fullName}</Card.Header>
        <Card.Meta>{employee.position}</Card.Meta>
        <Card.Description>
          {`Телефон: ${employee.phoneNumber}`}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
export default EmployeeData;
import React from 'react';
import moment from 'moment';

const getInterval = (from, to) => {
  if (from != null && to != null) {
    return `${moment(from).format('DD.MM.YYYY')} - ${moment(to).format('DD.MM.YYYY')}`
  }
  return null
}

const createList = (list, reportList, label) => {
  if (list.length === reportList.length) {
    return label
  }
  if (reportList) {
    return (
      reportList.map(item => {
        const name = list.find(({ id }) => id === item).name
        return (
          <span key={name}>{` ${name}, `} </span>)
      })
    )
  }
}

const produceSortOptions = [
  { key: 'areas', text: 'По регионам', value: 'areas' },
  { key: 'factory', text: 'По предприятиям', value: 'factory' },
  { key: 'date', text: 'По датам', value: 'date' },
  { key: 'products', text: 'По продуктам', value: 'products' },
]

const violationSortOptions = [
  { key: 'areas', text: 'По регионам', value: 'areas' },
  { key: 'factory', text: 'По предприятиям', value: 'factory' },
  { key: 'date', text: 'По датам', value: 'date' },
  { key: 'types', text: 'По нарушениям', value: 'types' },
]

const modes = [
  { id: 1, name: 'Промывка' },
  { id: 2, name: 'Калибровка' },
  { id: 3, name: 'Тех. прогон' },
  { id: 4, name: 'Производство' },
  { id: 5, name: 'Остановка' },
  { id: 7, name: 'Приемка' },
  { id: 9, name: 'Отгрузка' },
  { id: 10, name: 'Отгрузка(возврат)' }
];

// const modes = {
//   1: 'Промывка',
//   2: 'Калибровка',
//   3: 'Тех. прогон',
//   4: 'Производство',
//   5: 'Остановка',
//   7: 'Приемка',
//   9: 'Отгрузка',
//   10: 'Отгрузка(возврат)'
// };


export { getInterval, modes, createList, produceSortOptions, violationSortOptions };
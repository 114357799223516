import React, { Component } from 'react';
import { List, Segment } from 'semantic-ui-react';

import './list-factorys.css';

class ListFactorys extends Component {
  state = {
    activeItem: null
  }

  onClickItem = (id) => {
    const { onSelected } = this.props;
    this.setState({ activeItem: id });
    onSelected(id);
  }
  componentDidMount() {
    this.setState({ activeItem: this.props.selectedItem });
  }

  render() {
    const { items, selectedItem } = this.props;
    return (
      <Segment className="factory-list" >
        <List selection divided verticalAlign='middle' >
          {Array.isArray(items) ? (
            items.map(it => {
              const isActive = selectedItem === it.factoryId;
              const nameHeader = (isActive) ?
                'active-header' : 'header';
              return (
                <List.Item
                  key={it.factoryId}
                  active={isActive}
                  onClick={() => this.onClickItem(it.factoryId)}>
                  <List.Content className='factory-list-item'>
                      <List.Header className={nameHeader} >
                        {it.factoryName}
                      </List.Header>
                      {'Инн: ' + it.inn}
                  </List.Content>
                </List.Item>
              )
            })
          ) : (<div></div>)
          }
        </List>
      </Segment>
    )
  }
}

export default ListFactorys;
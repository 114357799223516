import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from '../../../utils';
import { Button } from 'semantic-ui-react';

import withAPI from '../../hoc/with-api';

import {
  GridMenu,
  PageLoader,
  PageMenuCurrent,
  MonitorFactory,
} from '../../views';

import CurrentState from '../current-state';
import ModulePage from '../module-page';
import UspdReportPage from '../uspd-report-page';
import InfoPage from '../info-page';
import {
  fetchFactorys,
  setFactorys,
  fetchTypesFactorys,
  setStoreParams,
  fetchAreas,
  setSelectedArea,
} from '../../../redux/actions';

import GMap from '../../map';


import * as factoryReducer from '../../../redux/reducers/reduce-factorys';
import * as areasReducer from '../../../redux/reducers/reduce-areas';
import * as paramReducer from '../../../redux/reducers/reduce-params';

import './monitor-page.css'

class MonitorPage extends Component {

  state = {
    search: '',
    activeTab: null,
    isLoad: false,
    selectedType: -1,
    selectedArea: -1,
  }


  basicTabs = {
    lines: {
      label: 'Линии',
      content: () => <CurrentState />,
      mobile: true,
    },
    modules: {
      label: 'Оборудование',
      content: () => <ModulePage />,
      mobile: true,
    },
    report: {
      label: 'Отчеты',
      content: () => <UspdReportPage />,
      mobile: true,
    },
    info: {
      label: 'Информация',
      content: () => <InfoPage />,
      mobile: true,
    },
    map: {
      label: <Button
        color='twitter'
        onClick={() => this.clickOnMap()}
        icon='world' />,
      content: () => null,
      mobile: false,
    },
  };

  clickOnMap = () => {
    const { setStatusMap, isVisibleMap } = this.props;
    setStatusMap(!isVisibleMap)
  }

  getInitialData = () => {
    const { api, setFactory, fetchFactorys, setFilteredFactorys } = this.props;
    api.getFactorys().then(({ data }) => {
      fetchFactorys(data);
      setFilteredFactorys(data)
      if (data && Array.isArray(data)) {
        setFactory(data[0].factoryId)
      }
    });
  }

  componentDidMount() {
    const { fetchTypesFactorys, fetchAreas } = this.props;
    fetchTypesFactorys();
    fetchAreas();

    this.getInitialData();
    if (!this.state.activeTab && Object.keys(this.basicTabs).length)
      this.clickMenuItem(Object.keys(this.basicTabs)[0])();
  }

  onSearchChange = (search) => {
    this.setState({ search });

    // const { filteredFactorys, setFilteredFactorys } = this.props;

    // let newList = filteredFactorys;
    // if (search.length > 0) {
    //   newList = filteredFactorys.filter((item) => {
    //     return item.factoryName.toLowerCase().indexOf(search.toLowerCase()) > -1;
    //   });
    // }
    // setFilteredFactorys(newList)
  }

  onChangeArea = (e, { value }) => {
    this.setState({ selectedArea: value })
    this.props.setSelectedArea(value)
  }

  searchItems() {
    const { search, selectedType, selectedArea } = this.state;
    const { factorys } = this.props;

    let filteredFactorys = factorys;
    if (selectedType > -1) {
      filteredFactorys = factorys.filter((item) => item.factoryType === selectedType)
    }

    if (search.length > 0) {
      filteredFactorys = filteredFactorys.filter((item) => {
        return item.factoryName.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    }

    if (selectedArea > -1) {
      filteredFactorys = filteredFactorys.filter((item) => item.area === selectedArea)
    }

    return filteredFactorys;
  }

  clickMenuItem = name => () => {
    console.log('clickMenuItem', name)
    if (name !== "map") {
      this.setState({ activeTab: name });
    }
  }

  onChangeType = (e, { value }) => {
    this.setState({ selectedType: value })

    // const { filteredFactorys, setFilteredFactorys, factorys } = this.props;
    // let newList = factorys;
    // if (selectedType > -1) {
    //   newList = factorys.filter((item) => item.factoryType === selectedType)
    // }

  }

  viewContent = (basicTabs, activeItem) =>
    basicTabs[activeItem] ? basicTabs[activeItem].content() : <div />;

  render() {
    const { selectedFactory, factoryTypes, areas, setFactory } = this.props;
    const { activeTab } = this.state;
    const visibleItems = this.searchItems();


    const monitorFactory = <MonitorFactory
      factoryTypes={factoryTypes}
      areas={areas}
      onChangeArea={this.onChangeArea}
      onChangeType={this.onChangeType}
      onSearchChange={this.onSearchChange}
      visibleItems={visibleItems}
      setFactory={setFactory}
      selectedFactory={selectedFactory}
    />

    if (!selectedFactory) {
      return <PageLoader />;
    }


    const currentData = (
      <div className='current-data'>
        <GMap />
        <PageMenuCurrent
          activeItem={activeTab}
          onClickItem={this.clickMenuItem}
        >
          {this.basicTabs}
        </PageMenuCurrent>
        <div className='content-tab'>
          {this.viewContent(this.basicTabs, activeTab)}
        </div>
      </div>
    )

    return (
      <GridMenu
        activeTab={activeTab}
        clickMenuItem={this.clickMenuItem}
        basicTabs={this.basicTabs}
        settings={monitorFactory}
        currentData={currentData}
        label='Предприятия'
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    factorys: factoryReducer.getFactorys(state),
    selectedFactory: factoryReducer.getSelected(state),
    factoryTypes: factoryReducer.getFactorysTypes(state),
    filteredFactorys: factoryReducer.getFilteredFactorys(state),
    areas: areasReducer.getAreas(state),
    isVisibleMap: paramReducer.getStatusMap(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    fetchFactorys: fetchFactorys(api, dispatch),
    setFactory: id => dispatch(setFactorys({ selectedFactory: id })),
    fetchTypesFactorys: _ => dispatch(fetchTypesFactorys(api)),
    fetchAreas: _ => dispatch(fetchAreas(api)),
    setStatusMap: status => dispatch(setStoreParams({ isVisibleMap: status })),
    setFilteredFactorys: factorys => dispatch(setFactorys({ filteredFactorys: factorys })),
    setSelectedArea: areaId => dispatch(setSelectedArea(areaId)),
  }
}

export default compose(
  withAPI(),
  connect(mapStateToProps, mapDispatchToProps)
)(MonitorPage);
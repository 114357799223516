import axios from 'axios';
import Authenticate from '../utils/auth';

//тестовый
// const baseURL = '//127.0.0.1:13245';//'192.168.0.73:1324';//'127.0.0.1:1324';
// const baseIP = 'asiiu-nsb.ru1.heiway.net';
// const baseIP = process.env.REACT_APP_API_URL;
// const baseIP = '10.8.1.98:1323';
// const baseURL = '//' + baseIP;

//боевой
const baseURL = '//uz-monitoring.zahp.ru';

const baseAPI = axios.create({
  baseURL: baseURL
});

const Auth = new Authenticate(baseAPI);

const getConfig = params => ({
  baseURL: baseURL,
  headers: {
    Authorization: Auth.accessToken()
  },
  params: params
});

const API = {
  GET: (path, params) => baseAPI.get(path, getConfig(params)),
  POST: (path, data) => baseAPI.post(path, data, getConfig()),
  PUT: (path, data) => baseAPI.put(path, data, getConfig()),
  DELETE: (path, params) => baseAPI.delete(path, getConfig(params))
};

class AppService {

  Login = data => Auth.authenticate(data);
  Logout = _ => API.GET('/logout').then(Auth.signout());
  isLogout = _ => API.GET('/islogout');
  isAuth = _ => Auth.isAuthenticated();

  getFactorys = _ => API.GET(`/factorys`);
  getTypesFactorys = _ => API.GET(`/factorys/types`);
  getLines = idFactory => API.GET(`/factorys/${idFactory}/lines`);
  getFactoryComplexes = idFactory => API.GET(`/factorys/${idFactory}/complexes`);
 
  //информация по выбранному заводу на вкладку информация
  getFactoryInformation = idFactory => API.GET(`/info/${idFactory}/information`);
  getFactoryEmployee = idFactory => API.GET(`/info/${idFactory}/employee`);
  getFactoryVersions = idFactory => API.GET(`/info/${idFactory}/versions`);

  //информация по комплексу
  //статусы модулей
  getModulesUspd = uspdNumber => API.GET(`/complex/${uspdNumber}/modules`);  
  //список линий комплекса
  getComplexLines = uspdNumber => API.GET(`/complex/${uspdNumber}/lines`);
  getComplexDevices = uspdNumber => API.GET(`/complex/${uspdNumber}/devices`);
  getReportLine = (uspdNumber, params) => API.GET(`/complex/${uspdNumber}/report`, params);

  //информация по линии
  //текущие показания
  getDetailsLine = lineId => API.GET(`/lines/${lineId}/details`);
  //состояния приборов (расходомер, счетчик)
  getDevicesLine = idLine => API.GET(`/lines/${idLine}/devices`);
  //информация о приборах
  getDevicesInfo = idLine => API.GET(`/lines/${idLine}/info`);
  
  //отчет на странице отчетности
  getReport = (sortingBy, params) => API.GET(`/report/${sortingBy}`, params);
  //отчет по нарушениям на страниц отчетов
  getViolations = (sortingBy, params) => API.GET(`/violations/${sortingBy}`, params);

  


  getAreas = _ => API.GET(`/areas`);

  getAllComplexes = _ => API.GET(`/complexes/all`);
  getComplexes = params => API.GET('/complexes', params);

}

export default AppService;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../views/spinner';
import { UspdReportData } from '../views';

import * as uspdReportReducer from '../../redux/reducers/reduce-uspd-report';

class UspdReportDataContainer extends Component {
  render() {
    const { loading, data } = this.props;
    if (loading) {
      return <Spinner />
    } else if (data !== undefined && Array.isArray(data.report)) {
      return <UspdReportData data={data} />
    } else {
      return <div />
    }
  }
}

const mapStateToProps = state => {
  return {
    loading: uspdReportReducer.getLoading(state),
    data: uspdReportReducer.getData(state),
  }
}

export default
  connect(
    mapStateToProps,
    null
  )(UspdReportDataContainer);
import React from 'react';
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";


const DateSelector = ({ date, func }) => {

    const onChange = (date) => {
        func(date);
    }

    return (
        <DatePicker
            locale={ru}
            selected={date}
            onChange={onChange}
            dateFormat="dd.MM.yyyy"
            className='date-picker'
        />
    );
}

export default DateSelector;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import { NameFactory, ReportHeader } from '../../views';
import Spinner from '../../views/spinner';
import UspdReportSettingsContainer from '../../containers/uspd-report-settings-container';
import UspdReportDataContainer from '../../containers/uspd-report-data-container';

import { modes, createList, getInterval } from '../../../utils/common';

import * as factoryReducer from '../../../redux/reducers/reduce-factorys';
import * as uspdReportReducer from '../../../redux/reducers/reduce-uspd-report';
import * as linesReducer from '../../../redux/reducers/reduce-lines';

import './uspd-report-page.css';


class UspdReportPage extends Component {

  createParamsList = () => {
    const { reportUspd, reportFrom, reportTo, reportRegimes, reportFactory } = this.props;
    const params = {
      "Предприятие: ": reportFactory,
      "УСПД: ": reportUspd,
      "Период отчета: ": getInterval(reportFrom, reportTo),
      "Выбранные режимы: ": createList(modes, reportRegimes, "Все режимы"),
    }
    return params;
  }

  render() {
    const { lines, infoSelectedFactory } = this.props;

    if (lines.length === 0) {
      return <Spinner />
    }

    return (
      <Segment className='uspd-report'>
        <NameFactory
          name={infoSelectedFactory.factoryName}
          inn={infoSelectedFactory.inn} />

        <UspdReportSettingsContainer />

        <ReportHeader params={this.createParamsList()} />
        <UspdReportDataContainer />
      </Segment >)
  }
}

const mapStateToProps = state => {
  const { reportFrom, reportTo } = uspdReportReducer.getReportInterval(state);
  return {
    reportFrom, reportTo,
    lines: linesReducer.getLines(state),
    infoSelectedFactory: factoryReducer.getInfoSelected(state),

    reportUspd: uspdReportReducer.getReportUspd(state),
    reportRegimes: uspdReportReducer.getReportRegimes(state),
    reportFactory: uspdReportReducer.getReportFactory(state),
  }
}

export default
  connect(
    mapStateToProps,
    null
  )(UspdReportPage);
import React from 'react';
import { Image } from 'semantic-ui-react';
import empty from './empty.png';
import './data-empty.css';

const DataEmpty = () => (
    <div className='empty'>
        <Image src={empty} />
        <p>Данных не найдено</p>
    </div>
);

export default DataEmpty;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReportParamList } from '../views'
import { compose } from '../../utils';
import withAPI from '../hoc/with-api';

import {
  fetchAreas,
  setReportParams,
} from '../../redux/actions';

import * as areasReducer from '../../redux/reducers/reduce-areas';
import * as reportReducer from '../../redux/reducers/reduce-report';


class ReportAreasContainer extends Component {

  componentDidMount() {
    const { fetchAreas } = this.props;
    fetchAreas();
  }

  setParams = (newList) => {
    console.log('setParams areas', newList)
    const { setReportParams, currentReport } = this.props;

    setReportParams({
      typeReport: currentReport,
      settings: { selectedAreas: newList }
    }
    );
  }

  render() {
    const { areas, reportAreas } = this.props;
    return (
      <ReportParamList key='areas-list'
        list={areas}
        reportList={reportAreas}
        setParams={this.setParams}
        label={'Все регионы'}
        nameKey={'areas'}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    areas: areasReducer.getAreas(state),
    reportAreas: reportReducer.getSelectedAreas(state),
    currentReport: reportReducer.getTypeReport(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    fetchAreas: _ => dispatch(fetchAreas(api)),
    setReportParams: params => dispatch(setReportParams(params)),
  }
}

export default compose(
  withAPI(),
  connect(mapStateToProps, mapDispatchToProps)
)(ReportAreasContainer);
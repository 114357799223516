import React from 'react';
import { EmployeeData } from '../../views';
import { List, Card } from 'semantic-ui-react';
import Spinner from '../../views/spinner';

import './factory-info.css';

const FactoryInfo = ({ information, employee }) => {
  if (employee === undefined) {
    return <Spinner />
  }
  if (employee === null) {
    return <div />
  }

  const info = (
    <List>
      <List.Item><span className="tag">Регион: </span>{information.areaStr}</List.Item>
      <List.Item><span className="tag">Адрес: </span>{information.factoryAddress}</List.Item>
      <List.Item><span className="tag">ИНН: </span>{information.inn}</List.Item>
    </List>
  )

  return (
    <Card.Group className='factory-group'>
      <Card>
        <Card.Content>
          <Card.Header>{information.factoryTypeStr}</Card.Header>
          <Card.Meta>Тип предприятия</Card.Meta>
          <Card.Description>{info}</Card.Description>
        </Card.Content>
      </Card>
      <EmployeeData employee={employee} />
    </Card.Group>
  )
}

export default FactoryInfo;
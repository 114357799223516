import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReportInterval } from '../views';

import "react-datepicker/dist/react-datepicker.css";

import {
  setReportParams,
} from '../../redux/actions';

import * as reportReducer from '../../redux/reducers/reduce-report';

class ReportIntervalContainer extends Component {
  funcFrom = (day) => {
    const { setReportParams, type } = this.props;
    // setReportParams(type, { from: day })
    setReportParams({
      typeReport: type,
      settings: { from: day }
    })
  }

  funcTo = (day) => {
    const { setReportParams, type } = this.props;
    // setReportParams(type, { to: day })
    setReportParams({
      typeReport: type,
      settings: { to: day }
    })
  }

  render() {
    const { from, to } = this.props;
    return (
      <ReportInterval
        funcFrom={this.funcFrom}
        funcTo={this.funcTo}
        from={from}
        to={to}
      />
    );
  }
}

const mapStateToProps = state => {
  const { from, to } = reportReducer.getInterval(state);
  return {
    from,
    to,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setReportParams: params => dispatch(setReportParams(params)),
  }
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReportIntervalContainer);
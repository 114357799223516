import {
  SET_FACTORYS,
} from '../actions';

import { reduceAdd } from './reducers';

const initialState = {
  factorys: [],
  filteredFactorys: [],
  selectedFactory: null,
  complexes: [],
  types: [],
}

export const factorys = (state = initialState, action) => {
  switch (action.type) {
    case SET_FACTORYS:
      return reduceAdd(state, action.payload);

    default:
      return state;
  };
};

export const getSelected = ({ factorys }) => {
  return factorys.selectedFactory;
}

export const getInfoSelected = ({ factorys }) => {
  let info = null
  if (factorys.factorys.length > 0
    && Array.isArray(factorys.factorys)
    && factorys.selectedFactory
    && factorys.factorys.map((item) => {
      if (item.factoryId === factorys.selectedFactory) {
        info = item
      }
    })
  )

    return info;
}

export const getComplexes = ({ factorys }) => {
  return factorys.complexes;
}

export const getFactorys = ({ factorys }) => {
  return factorys.factorys;
}

export const getFactorysTypes = ({ factorys }) => {
  return factorys.types;
}

export const getFilteredFactorys = ({ factorys }) => {
  return factorys.filteredFactorys;
}

import React from 'react';
import { DataVisual, DataTable, AccumData } from '../../views';
import { Divider, Header } from 'semantic-ui-react';

import './uspd-report-data.css'

const UspdReportData = ({ data }) => {
  console.log('data', data)
  return (
    <div className='uspd-report-data'>
      <Divider horizontal>Отчет</Divider>
      {data.total !== undefined ?
        <AccumData
          header="Суммарные данные по линиям"
          reportLine={data.total} /> : null}

      {data.totalViolations !== undefined ?
        <b>Всего правонарушений: {data.totalViolations}</b> : null}

      {data.report.map((line) => {
        let name = line.name;
        if (line.uspdNumber !== undefined && line.pointOfControl !== undefined) {
          name += ` (${line.uspdNumber}_${line.pointOfControl})`
        }
        if (line.totalViolations !== undefined) {
          name += `, правонарушений: ${line.totalViolations}`
        }
        return (
          <div key={line.key} >
            <Header className='line-name' as='h5' >{name}</Header>
            <div className='uspd-report-data-container'>
              <DataVisual
                visual={DataTable}
                data={line.reportLines}
                footer={line.total}
              />
            </div>
          </div>
        )
      })
      }
    </div>
  )
}



export default UspdReportData;
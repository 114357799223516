import React from 'react';
import { Table } from 'semantic-ui-react';

import DateSelector from '../../../utils/calendar/date';

import './report-interval.css';

const ReportInterval = ({funcFrom, funcTo, from, to }) => {
  return (
    <div className='report-date-selector'>
      <Table unstackable basic='very' collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {<span >с</span>}
            </Table.Cell>
            <Table.Cell>
              <DateSelector className='from' func={funcFrom} date={from} />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              {<span >по</span>}
            </Table.Cell>
            <Table.Cell>
              <DateSelector className='to' func={funcTo} date={to} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

    </div>

    // <Grid className='report-date-selector'>
    //   <Grid.Row>
    //     <Grid.Column >
    //       <Header as='h4' textAlign='center'>
    //         <p>с</p>
    //       </Header>
    //     </Grid.Column>
    //     <Grid.Column width={12}>
    //       <DateSelector className='from' func={funcFrom} date={from} />
    //     </Grid.Column>
    //   </Grid.Row>

    //   <Grid.Row>
    //     <Grid.Column className='interval-header'>
    //       {<span >по</span>}
    //     </Grid.Column>
    //     <Grid.Column width={12}>
    //       <DateSelector className='to' func={funcTo} date={to} />
    //     </Grid.Column>
    //   </Grid.Row>
    // </Grid>



    // <Menu vertical className='date-selector'>
    //   {title ? <Menu.Item>&nbsp;<h5>{title}</h5>&nbsp;</Menu.Item> : null}
    //   <Menu.Item>
    //   <Header as='h4'>С</Header>
    //     <DateSelector className='from' func={funcFrom} date={from} />
    //   </Menu.Item>
    //   <Menu.Item>
    //     {'по'}
    //     <DateSelector className='to' func={funcTo} date={to} />
    //   </Menu.Item>
    //   {/* {children ? <Menu.Item position='right'>{children}</Menu.Item> : null} */}
    // </Menu>
  )
}

export default ReportInterval;
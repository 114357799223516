import React from 'react';
import LoadingScreen from 'react-loading-screen';
import logo from './logo.png';
import './page-loader.css';

const PageLoader = () => (
    <LoadingScreen
        loading={true}
        bgColor='wight'
        spinnerColor='#008000'
        logoSrc={logo}
    >
        <div />
    </LoadingScreen>
);
export default PageLoader;

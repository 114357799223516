class Authenticate {
  constructor(axios) {
    this.axios = axios;
  };

  accessToken = () => 'Bearer ' + sessionStorage.getItem('access_token');

  checkResponseToken = (data) => data.hasOwnProperty('access_token');

  isAuthenticated = () => {
    const access_token = sessionStorage.getItem('access_token');
    return access_token != null && access_token !== '';
  };
  
  authenticate = (data) => this.axios.post('/login', data).then(({ data }) => {
      if (this.checkResponseToken(data) && data.access_token !== ''){
        sessionStorage.setItem('access_token', data.access_token);
      }
      return data
  });
  
  signout() {
    console.log('signout')
     sessionStorage.clear();
  };
}

export default Authenticate;

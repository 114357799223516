import { combineReducers } from 'redux';

import { factorys } from './reduce-factorys';
import { areas } from './reduce-areas';
import { lines } from './reduce-lines';
import { params } from './reduce-params';
import { modules } from './reduce-modules';
import { devices } from './reduce-devices';
import { uspdReport } from './reduce-uspd-report';
import { information } from './reduce-info';
import { report } from './reduce-report';

export default () => combineReducers({
  areas,
  factorys,
  lines,
  params,
  modules,
  devices,
  uspdReport,
  information,
  report
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import './calendar.css';

import { 
  setParam,
} from '../../redux/actions';

import DateSelector from './date';

class CalendarDay extends Component {
  funcDay = (day) => {
    this.props.onGetDay(day)
  }

  render() {
    const { params: { day }, children, title }= this.props;
    return(
      <Menu className='date-selector'>
        {title ? <Menu.Item>&nbsp;<h5>{title}</h5>&nbsp;</Menu.Item> : null}
        <Menu.Item>
          <DateSelector className='day' func={this.funcDay} date={day} />
        </Menu.Item>
        {children ? <Menu.Item position='right'>{children}</Menu.Item> : null}
      </Menu>
    );
  }
}
  
const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
  return {
    onGetDay: day => dispatch(setParam({ day }))
  };
}; 

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDay);
import React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import { PopupMessage } from '../index';

import './line-info.css'

const modes = {
  1: 'Промывка',
  2: 'Калибровка',
  3: 'Тех. прогон',
  4: 'Производство',
  5: 'Остановка',
  7: 'Приемка',
  9: 'Отгрузка'
};

const parseDate = date => `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(11, 16)}`;

const LineInfo = ({ line, isActive }) => {
  const {
    pointOfControl,
    lineName,
    productId: productName,
    massFlow,
    beginTime,
    regime
  } = line;

  return (
    <Segment className={isActive ? 'on' : 'off'}>
      <Table basic='very' celled unstackable
        className='line-info-table'>
        <Table.Body>
          <Table.Row>
            <PopupMessage content='Линия' side='top'>
              <Table.Cell
                className='line name'
                colSpan='1'
              >{`${lineName} (${pointOfControl})`}</Table.Cell>
            </PopupMessage>
            <PopupMessage content='Название продукта' side='right'>
              <Table.Cell className='line product' colSpan='2'>
                {productName === 'DEF_PRODUCT_NAME_COD'
                  ? 'КОД ПРОДУКТА ПО УМОЛЧАНИЮ'
                  : productName}
              </Table.Cell>
            </PopupMessage>
          </Table.Row>
          <Table.Row>
            <PopupMessage
              content='Время начала сессии'
              side='bottom'
            >
              <Table.Cell className='line time' colSpan='1'>
                {parseDate(beginTime)}
              </Table.Cell>
            </PopupMessage>
            <PopupMessage content='Режим' side='bottom'>
              <Table.Cell
                className='line regime'
                colSpan='1'
              >{`${modes[regime]}(${regime})`}</Table.Cell>
            </PopupMessage>
            <PopupMessage content='Расход' side='right'>
              <Table.Cell
                className={`line ${
                  massFlow < 0 ? 'error' : ''
                  }`}
                colSpan='1'
              >{`${massFlow !== undefined ? massFlow.toFixed(2) : 0} дал/ч`}</Table.Cell>
            </PopupMessage>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
};

export default LineInfo;
import React, { Component } from 'react';
import { Button, Grid, Menu, Sidebar, Segment } from 'semantic-ui-react';

import {
  PageVisual,
  PageMenuCurrentMobile,
} from '../../views';

import './grid-menu.css';

class GridMenu extends Component {
  state = {
    isVisibleSettings: false,
  }

  changeVibleSettings = (state) => this.setState({ isVisibleSettings: state })

  render() {
    const { activeTab, clickMenuItem, basicTabs, settings, currentData, label } = this.props;
    const { isVisibleSettings } = this.state;

    return (
      <Grid columns={1} className='monitor-page-content'>
        <Grid.Row className='row-mobile' columns={2}>

          <Grid.Column >
            <PageMenuCurrentMobile
              activeItem={activeTab}
              onClickItem={clickMenuItem} >
              {basicTabs}
            </PageMenuCurrentMobile>
          </Grid.Column >

          <Grid.Column align='right'>
            <Button className='btn-settings'
              basic
              compact
              content={label}
              toggle
              active={isVisibleSettings}
              circular icon='settings'
              color='blue'
              onClick={() => this.changeVibleSettings(!isVisibleSettings)}
            >
            </Button>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className='mobile-data'>
          <Grid.Column>
            <Sidebar.Pushable as={Grid} className='side-pushable'>
              <Sidebar className='side-menu'
                as={Menu}
                animation='overlay'
                direction='right'
                // icon='labeled'
                onHide={() => this.changeVibleSettings(false)}
                vertical
                visible={isVisibleSettings}
                width='wide'
              >
                <Menu.Item >
                  {settings}
                </Menu.Item>

              </Sidebar>

              <Sidebar.Pusher
                dimmed={isVisibleSettings}
              >
                <Segment basic className='mobile-tab-data'>
                  {currentData}
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='computer-data'>
          <PageVisual
            page='monitor-page'
            firstColumn={settings}
            secondColumn={currentData}
          />
        </Grid.Row>
      </Grid>
    )
  }
}

export default GridMenu;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from '../../../utils';
import withAPI from '../../hoc/with-api';
import { Versions, NameFactory, FactoryInfo } from '../../views';

import {
  fetchFactoryInformation,
} from '../../../redux/actions';

import * as factoryReducer from '../../../redux/reducers/reduce-factorys';
import * as infoReducer from '../../../redux/reducers/reduce-info';
import { Segment, Divider, Grid } from 'semantic-ui-react';
import Spinner from '../../views/spinner';

import './info-page.css'

class InfoPage extends Component {

  componentDidMount() {
    const { selectedFactory, fetchFactoryInformation } = this.props;
    fetchFactoryInformation(selectedFactory)
  }

  componentDidUpdate(prevProps) {
    const { selectedFactory, fetchFactoryInformation } = this.props;
    if (selectedFactory !== prevProps.selectedFactory) {
      fetchFactoryInformation(selectedFactory)
    }
  }

  render() {
    const { information, employee, versions, infoSelectedFactory } = this.props;

    if (information === undefined) {
      return <Spinner />
    }

    return (
      <Segment className='info-page'>
        <NameFactory
          name={infoSelectedFactory.factoryName}
          inn={infoSelectedFactory.inn} />

        <FactoryInfo
          information={information}
          employee={employee}
        />

        <Divider horizontal className="soft-info">
          <p>Версии программного обеспечения</p>
        </Divider>
        <Grid.Row className='software'>{Versions(versions)}</Grid.Row>
      </Segment>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedFactory: factoryReducer.getSelected(state),
    infoSelectedFactory: factoryReducer.getInfoSelected(state),
    information: infoReducer.getInformation(state),
    employee: infoReducer.getEmployee(state),
    versions: infoReducer.getVersions(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    fetchFactoryInformation: id => fetchFactoryInformation(id, api, dispatch),
  }
}

export default compose(
  withAPI(),
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(InfoPage);
import React, { Component } from 'react';
import { Divider, Accordion, Segment, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { compose } from '../../../utils';
import withAPI from '../../hoc/with-api';

import {
  fetchFactoryComplexes,
  setSelectedUspd,
  fetchDevices,
  fetchModules,
  setFactorys,
} from '../../../redux/actions';
import DeviceContainer from '../../containers/device-container';
import ModuleContainer from '../../containers/module-container';

import * as deviceReducer from '../../../redux/reducers/reduce-devices';
import * as factoryReducer from '../../../redux/reducers/reduce-factorys';
import { NameFactory } from '../../views';

import './module-page.css'

class ModulePage extends Component {

  componentDidMount() {
    this.getFirstData();
    this.timerUpdate = setInterval(() => {
      this.getData();
    }, 100000);
  }

  componentDidUpdate(prevProps) {
    const { selectedFactory, selectedUspd } = this.props;
    if (selectedFactory !== prevProps.selectedFactory) {
      this.getFirstData();
    }
    if (selectedUspd !== prevProps.selectedUspd) {
      this.getData();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerUpdate);
  }

  getFirstData = () => {
    const { selectedFactory, api, setSelectedUspd, setComplexes } = this.props;
    setSelectedUspd(null)
    api.getFactoryComplexes(selectedFactory).then(({ data }) => {
      setComplexes(data);
      if (data && Array.isArray(data)) {
        setSelectedUspd(data[0].uspdNumber)
        this.getData();
      }
    })
  }

  getData = () => {
    const { selectedUspd, fetchDevices, fetchModules } = this.props;
    fetchDevices(selectedUspd);
    fetchModules(selectedUspd);
    // fetchDevicesInfo(selectedLine);
  }

  handleClick = (e, titleProps) => {
    const { selectedUspd, setSelectedUspd } = this.props;
    const { index } = titleProps

    if (selectedUspd !== index) {
      setSelectedUspd(index)
    }
  }

  render() {
    const { selectedUspd, complexes, infoSelectedFactory } = this.props;

    if (!selectedUspd && Object.keys(complexes).length) {
      this.handleClick(null, { index: (complexes)[0].uspdNumber })
    }

    return (
      <Segment className='module-page'>
          <NameFactory
            name={infoSelectedFactory.factoryName}
            inn={infoSelectedFactory.inn} />
        <Accordion styled fluid>
          {Array.isArray(Object.keys(complexes)) && (
            Object.keys(complexes).map(uspd => {
              const complex = complexes[uspd]
              const isActive = selectedUspd === complex.uspdNumber
              return (
                <div key={complex.uspdNumber}>
                  <Accordion.Title
                    active={isActive}
                    index={complex.uspdNumber}
                    onClick={this.handleClick}
                  >
                    <Icon name='dropdown' />
                    {'УСПД: ' + complex.uspdNumber}

                  </Accordion.Title>
                  <Accordion.Content active={isActive}>
                    <ModuleContainer
                      uspd={complex}
                    />
                    <Divider horizontal>Оборудование</Divider>
                    <DeviceContainer
                      uspd={complex}
                    />
                  </Accordion.Content>
                </div>
              )
            })
          )
          }
        </Accordion>
      </Segment>
    )
  }
}

const mapStateToProps = (state) => {
  const { selectedUspd } = deviceReducer.getDevices(state);
  return {
    selectedUspd,
    selectedFactory: factoryReducer.getSelected(state),
    complexes: factoryReducer.getComplexes(state),
    infoSelectedFactory: factoryReducer.getInfoSelected(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    fetchFactoryComplexes: id => fetchFactoryComplexes(id, api, dispatch),
    fetchDevices: uspdNumber => fetchDevices(uspdNumber, api, dispatch),
    fetchModules: uspdNumber => fetchModules(uspdNumber, api, dispatch),
    setSelectedUspd: uspdNumber => dispatch(setSelectedUspd(uspdNumber)),

    setComplexes: data => dispatch(setFactorys({ complexes: data })),

    // fetchDevicesInfo: lineId => fetchDevicesInfo(lineId, api, dispatch),
  }
}

export default compose(
  withAPI(),
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(ModulePage);


import React from 'react';
import { Table } from 'semantic-ui-react';

import imgError from '../../../images/error.png';
import imgSuccess from '../../../images/success.png';
import imgNotFound from '../../../images/not_found.png';

import './modules-status.css';

const dictStatusModules = {
  0: <img src={imgSuccess} width="20" />,//'подключен',
  1: <img src={imgError} width="20" />,//'нет подключения',
  2: 'ошибка'
};

const dictStatusStyle = {
  0: 'positive',//'подключен',
  1: 'error',//'нет подключения',
  2: 'ошибка'
};

const setStatus = (modules, name) => {
  const idx = modules.findIndex(({ destination }) => destination === name)
  if (idx > -1) {
    const style = dictStatusStyle[modules[idx].status];
    return (
      <Table.Cell className={style}>
        {dictStatusModules[modules[idx].status]}
      </Table.Cell>
    )
  } else {
    return <Table.Cell className='positive'><img src={imgNotFound} width="20" /></Table.Cell>
  }
}

const ModulesStatus = ({ modules }) => {
  return (
      <Table unstackable celled selectable className='table-data'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Папка выгрузки</Table.HeaderCell>
            <Table.HeaderCell>ИБП</Table.HeaderCell>
            <Table.HeaderCell>Модуль клапанов</Table.HeaderCell>
            <Table.HeaderCell>Температурный модуль</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {setStatus(modules, "SRV")}
            {setStatus(modules, "UPS")}
            {setStatus(modules, "VALVE")}
            {setStatus(modules, "TEMP")}
          </Table.Row>
        </Table.Body>
      </Table>
  )
}

export default ModulesStatus;
import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';

class SearchPanel extends Component {

  state = {
    term: ''
  }

  onTermChange = (e) => {
    const { onSearchChange = () => { } } = this.props;
    this.setState({
      term: e.target.value
    });

    onSearchChange(e.target.value);
  }

  render() {
    return (
      <Input
        className='search'
        icon='search'
        placeholder='Поиск...'
        value={this.state.term}
        onChange={this.onTermChange}
      />
    )
  }
}

export default SearchPanel;
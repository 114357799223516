import React, { Component } from 'react';
import { Segment, Menu } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { compose } from '../../../utils';
import {
  setLines,
  setSelectedLine,
  fetchLines,
  fetchDetails,
  fetchDevicesInfo
} from '../../../redux/actions';

import withAPI from '../../hoc/with-api';
import LinesContainer from '../../containers/lines-container';

import * as factoryReducer from '../../../redux/reducers/reduce-factorys';
import * as linesReducer from '../../../redux/reducers/reduce-lines';

import './current-state.css';
import Spinner from '../../views/spinner';
import { NameFactory } from '../../views';

class CurrentState extends Component {

  componentDidMount() {
    this.getInitialData();
    this.timerUpdate = setInterval(() => {
      this.getData();
    }, 100000);
  }

  componentWillUnmount() {
    clearTimeout(this.timerUpdate);
  }

  componentDidUpdate(prevProps) {
    const { selectedFactory, selectedLine } = this.props;

    if (selectedFactory !== prevProps.selectedFactory) {
      this.getInitialData();
    }
    if (selectedLine !== prevProps.selectedLine) {
      this.getData()
    }
  }

  getInitialData = () => {
    const { api, selectedFactory, setSelectedLine } = this.props;
    setSelectedLine(null)
    api.getLines(selectedFactory).then(({ data }) => {
      setLines(data);
      if (data && Array.isArray(data) && Array.isArray(data[0].lines)) {
        setSelectedLine(data[0].lines[0].lineId)
      }
      this.getData()
    })
  }

  getData = () => {
    const { selectedFactory, fetchLines,
      fetchDetails, selectedLine, fetchDevicesInfo } = this.props;
    fetchLines(selectedFactory);
    fetchDetails(selectedLine);
    fetchDevicesInfo(selectedLine);
  }

  render() {
    const { lines, infoSelectedFactory } = this.props;

    if (lines.length === 0) {
      return <Spinner />
    }
    return (
      <Segment className='current-state'>
        <div >
          <NameFactory
            name={infoSelectedFactory.factoryName}
            inn={infoSelectedFactory.inn} />

          <Menu secondary fluid vertical className='uspd-list'>
            {Array.isArray(Object.keys(lines)) && (
              Object.keys(lines).map(uspd => {
                return (
                  <LinesContainer
                    key={uspd}
                    lines={lines[uspd].lines}
                    uspdNumber={lines[uspd].uspdNumber}
                  />
                )
              })
            )}
          </Menu>
        </div>
      </Segment>
    )
  }
}

const mapStateToProps = state => {
  const { selectedLine } = linesReducer.getDetails(state);
  return {
    selectedLine,
    lines: linesReducer.getLines(state),
    selectedFactory: factoryReducer.getSelected(state),
    infoSelectedFactory: factoryReducer.getInfoSelected(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    fetchLines: idFactory => dispatch(fetchLines(idFactory, api)),
    fetchDetails: lineId => fetchDetails(lineId, api, dispatch),
    setSelectedLine: id => dispatch(setSelectedLine(id)),
    setLines: data => dispatch(setLines(data)),
    fetchDevicesInfo: lineId => fetchDevicesInfo(lineId, api, dispatch),
  }
}

export default compose(
  withAPI(),
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(CurrentState);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModulesStatus } from '../views';
import Spinner from '../views/spinner';

import * as moduleReducer from '../../redux/reducers/reduce-modules';


class ModuleContainer extends Component {

  createModuleTable = (modules) => {
    if (modules === null || modules === undefined) {
      return null
    }
    return (
      <ModulesStatus modules={modules} />
    )
  }

  render() {
    const { modules,
      loading,
      selectedUspd,
      uspd: { uspdNumber } } = this.props;
    if (loading) {
      return <Spinner />
    } else if (uspdNumber === selectedUspd) {
      return this.createModuleTable(modules)//<DataVisual visual={DataTable} data={modules} />
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => {
  const { modules, selectedUspd } = moduleReducer.getModules(state);
  return {
    modules,
    selectedUspd,
    loading: moduleReducer.getLoading(state)
  }
}

export default
  connect(
    mapStateToProps
  )(ModuleContainer);
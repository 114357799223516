import React from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import imgComplex from '../../images/factory.png';
import imgSelectedComplex from '../../images/factory_selected.png';

const setImage = (factoryId, selectedFactory) => {
  return factoryId === selectedFactory ? imgSelectedComplex : imgComplex
}

const CurrLocation = ({ factorys,
  selectedFactory,
  onClickItem }) => {
  return (
    <div>
      {factorys != null && Object.keys(factorys).length && factorys.map(factory => {
        return (
          <Marker
            key={factory.factoryId}
            draggable={false}
            position={{
              lat: factory.latitude,
              lng: factory.longitude
            }}
            icon={setImage(factory.factoryId, selectedFactory)}
            title={factory.factoryName}
            onClick={() => {
              onClickItem(factory.factoryId);
            }}
          >
            {factory.factoryId === selectedFactory ? (<InfoWindow>
              <div>{factory.factoryName}</div>
            </InfoWindow>) : (<div></div>)
            }
          </Marker>
        )
      })
      }
    </div>
  )
}

export default CurrLocation;
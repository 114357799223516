import React from 'react';
import moment from 'moment';
import { Table, TableRow } from 'semantic-ui-react';

import imgError from '../../../images/error.png';
import imgSuccess from '../../../images/success.png';

import './data-table.css';

const columns = {
    pointOfControl: {
        name: 'Точка учета'
    },
    destination: {
        name: 'Модуль',
        handler: ({ destination }) => dictModules[destination]
    },
    dateinsert: {
        name: 'Дата добавления',
        handler: ({ dateinsert }) =>
            moment(dateinsert).format('DD.MM.YYYY HH:mm')
    },
    savedate: {
        name: 'Дата файла',
        handler: ({ savedate }) => moment(savedate).format('DD.MM.YYYY')
    },
    status: {
        name: 'Статус',
        handler: ({ status }, j) =>
            j === 1 ? dictStatusModules[status] : dictStatusUtm[status]
    },
    error: {
        name: 'Ошибка'
    },
    conclusion: {
        name: 'Фиксация'
    },
    conclusionData: {
        name: 'Дата фиксации',
        handler: ({ conclusionData }) =>
            moment(conclusionData).format('DD.MM.YYYY')
    },
    typeName: {
        name: 'Тип'
        // handler: ({ type }) => dictType[type]
    },
    device: {
        name: 'Прибор'
        // handler: ({ type }) => dictType[type]
    },
    model: {
        name: 'Модель'
    },
    serialNumber: {
        name: 'Серийный номер'
    },
    verification: {
        name: 'Дата следущей поверки',
        handler: ({ verification, period }) =>
            moment(verification)
                .add(period, 'years')
                .format('DD.MM.YYYY')
    },
    flagInit: {
        name: 'Обновление',
        handler: ({ flagInit }) => dictInit[flagInit]
    },
    timeUpdate: {
        name: 'Время обновления'
    },
    regime: {
        name: 'Режим',
        handler: ({ regime }) => regime === 0 ? null : regime
    },
    alkoVolume: {
        name: 'Объем',
        handler: ({ alkoVolume }) => alkoVolume !== undefined ? alkoVolume.toFixed(2) : 'NaN',
    },
    alkoVolume100: {
        name: 'Объем безводный',
        handler: ({ alkoVolume100 }) => alkoVolume100 !== undefined ? alkoVolume100.toFixed(2) : 'NaN',
    },
    bottlesCounts: {
        name: 'Количество бутылок'
    },
    fullName: {
        name: 'Продукт'
    },
    endTime: {
        name: 'Время окончания сессии',
        handler: ({ endTime }) => endTime === "" ? null : moment(endTime).format('DD.MM.YYYY HH:mm'),
    },
    factoryName: {
        name: 'Предприятие'
    },
    endDate: {
        name: 'Дата',
        handler: ({ endDate }) => endDate === "" ? null : moment(endDate).format('DD.MM.YYYY')
    },
    violationName: {
        name: 'Нарушение'
    },
    lineName: {
        name: 'Линия'
    },
    violationDate: {
        name: 'Дата нарушения',
        handler: ({ violationDate }) =>
            moment(violationDate).format('DD.MM.YYYY')
    },
    averAlko: {
        name: 'Крепость',
        handler: ({ averAlko }) => averAlko !== undefined ? averAlko.toFixed(1) : 'NaN',
    },
    alсTu: {
        name: 'Крепость по ТУ',
        handler: ({ alсTu }) => alсTu !== undefined ? alсTu.toFixed(1) : 'NaN',
    },
};

const dictStatusModules = {
    0: <img src={imgSuccess} alt="0" width="20" />,//'подключен',
    1: <img src={imgError} alt="1" width="20" />,//'нет подключения',
    2: 'ошибка'
};

const dictStatusUtm = {
    // 1: 'Ошибка запрса',
    // 2: 'В процессе',
    // 3: 'Успешно',
    // 4: 'Потеряно соединение',
    // 5: 'Новая задача',
    // 6: 'Соединение установлено',
    // 7: 'Ошибка таблицы плотностей',
    8: 'Сохранено в локальную дирректорию',
    9: 'Отправлено в УТМ',
    10: 'Получен ответ от УТМ',
    11: 'Завершено'
};

const dictModules = {
    JACARTA: 'JACARTA',
    RUTOKEN: 'RUTOKEN',
    SRV: 'Папка выгрузки',
    TEMP: 'Температурный модуль',
    UPS: 'ИПБ',
    UTM: 'УТМ',
    VALVE: 'Модуль клапанов'
};

// const dictType = {
//     82: 'Расходомер ПРОМАГ',
//     81: 'Расходомер ПРОМАСC',
//     79: 'Cчетчик УСБ',
//     80: 'Cчетчик C-30',
//     flowmeter: 'Расходомер',
//     thermistor: 'Термосопротивление',
//     complex: 'Шкаф'
// };

const dictInit = {
    128: <img src={imgError} alt="128" width="20" />,//'Ошибка',
    205: <img src={imgSuccess} alt="205" width="20" />,//'Успешно',
    153: 'Ошибка входа',
    156: 'Пустые файлы'
};

const getData = row =>
    Object.keys(row).map((nameField, j) => {
        if (columns[nameField]) {
            const { handler } = columns[nameField];
            const values = handler ? handler(row, j) : row[nameField];

            const diffDurationConclusion =
                moment
                    .duration(
                        moment(row['conclusionData']).diff(
                            moment(row['savedate'])
                        )
                    )
                    .days() > 1;

            let clazz =
                row['flagInit'] === 205 || row['status'] === 0
                    ? 'positive'
                    : '';
            clazz = row['days'] <= 31 ? 'warning' : clazz;
            clazz =
                row['days'] <= 14 ||
                    row['flagInit'] === 128 ||
                    row['status'] === 1 ||
                    (row['status'] === 9 && row['error'] === 203) ||
                    (row['status'] === 10 &&
                        row['error_code'] === 207 &&
                        (row['conclusion'] === 'Rejected' ||
                            diffDurationConclusion))
                    ? 'error'
                    : clazz;

            return (
                <Table.Cell key={nameField} className={clazz}>
                    {values}
                </Table.Cell>
            );
        }
        return null;
    });

const getFooterCell = (nameColumn, row) => {
    let value = null;
    if (Object.keys(row).findIndex(item => item === nameColumn) != -1) {
        const { handler } = columns[nameColumn];
        const values = handler ? handler(row, nameColumn) : row[nameColumn];
        value = values;
    }
    return <Table.HeaderCell key={`footer${nameColumn}`}>{value}</Table.HeaderCell>
}

const DataTable = (data, footer) => {
    return (
        <Table celled unstackable selectable className='table-data'>
            <Table.Header>
                <Table.Row>
                    {data[0] &&
                        Object.keys(data[0]).map(nameColumn =>
                            columns[nameColumn] ? (
                                <Table.HeaderCell key={nameColumn}>
                                    {columns[nameColumn].name}
                                </Table.HeaderCell>
                            ) : null
                        )}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data[0] &&
                    data.map((row, i) => {
                        return <Table.Row key={i}>{getData(row)}</Table.Row>;
                    })}
            </Table.Body>
            {(footer !== undefined) ? (
                <Table.Footer>
                    <Table.Row key='footer'>
                        {data[0] &&
                            Object.keys(data[0]).map(nameColumn =>
                                columns[nameColumn] ? (
                                    getFooterCell(nameColumn, footer)
                                ) : null
                            )}
                    </Table.Row>
                </Table.Footer>
            ) : null}
        </Table>
    );
}

export default DataTable;

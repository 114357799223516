import React from 'react';
import { Divider } from 'semantic-ui-react';
import './name-factory.css';

const NameFactory = ({ name, inn }) => (
  <Divider className='name-fatory' horizontal>
    <p>
      {name + '. ИНН: ' + inn}
    </p>
  </Divider>
)

export default NameFactory;
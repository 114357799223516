import moment from 'moment';

const create_action_creator = (type) => (payload) => ({ type: type, payload: payload });

export const SET_FACTORYS = 'SET_FACTORYS';
export const setFactorys = create_action_creator(SET_FACTORYS);

const fetchFactorys = ({ getFactorys }, dispatch) => () => {
  getFactorys().then(({ data }) => {
    dispatch(setFactorys({ factorys: data }))
  })
}

const fetchFactoryComplexes = (idFactory, { getFactoryComplexes }, dispatch) => {
  getFactoryComplexes(idFactory).then(({ data }) => {
    dispatch(setFactorys({ complexes: data }));
  })
}

const fetchTypesFactorys = ({ getTypesFactorys }) => (dispatch) => {
  getTypesFactorys().then(({ data }) => {
    dispatch(setFactorys({ types: data }))
  })
}


//информация по заводу на вкладку Информация
export const SET_FACTORY_INFORMATION = 'SET_FACTORY_INFORMATION';
export const setFactoryInformation = create_action_creator(SET_FACTORY_INFORMATION);

const fetchFactoryInformation = (idFactory,
  { getFactoryInformation,
    getFactoryEmployee,
    getFactoryVersions }, dispatch) => {
  dispatch(setFactoryInformation({
    information: undefined,
    employee: undefined,
    versions: []
  }))
  getFactoryInformation(idFactory).then(({ data }) => dispatch(setFactoryInformation({ information: data })));
  getFactoryEmployee(idFactory)
    .then(({ data }) => dispatch(setFactoryInformation({ employee: data })))
    .catch((error) => {
      dispatch(setFactoryInformation({ employee: null }));
    });
  getFactoryVersions(idFactory).then(({ data }) => dispatch(setFactoryInformation({ versions: data })));
}

//статусы состояний приборов на линии
export const SET_DEVICES = 'SET_DEVICES';
const setDevices = create_action_creator(SET_DEVICES);

const fetchDevices = (uspdNumber, { getComplexDevices }, dispatch) => {
  getComplexDevices(uspdNumber).then(({ data }) => {
    dispatch(setDevices(data))
  })
}

export const SET_SELECTED_USPD = 'SET_SELECTED_USPD';
export const setSelectedUspd = create_action_creator(SET_SELECTED_USPD);


//modules
export const SET_MODULES = 'SET_MODULES';
export const setModules = create_action_creator(SET_MODULES);

const fetchModules = (uspdNumber, { getModulesUspd }, dispatch) => {
  getModulesUspd(uspdNumber).then(({ data }) => {
    dispatch(setModules(data))
  });
};

//информация по линиям
export const SET_LINES = 'SET_LINES';
export const setLines = create_action_creator(SET_LINES);

const fetchLines = (idFactory, { getLines }) => (dispatch) => {
  getLines(idFactory).then(({ data }) => {
    dispatch(setLines(data))
  });
};

export const SET_DETAILS = 'SET_DETAILS';
export const setDetails = create_action_creator(SET_DETAILS);

const fetchDetails = (lineId, { getDetailsLine }, dispatch) => {
  if (lineId > 0) {
    getDetailsLine(lineId).then(({ data }) => {
      dispatch(setDetails(data))
    })
  }
}

export const SET_LINEINFO = 'SET_LINEINFO';
export const setLineInfo = create_action_creator(SET_LINEINFO);

const fetchDevicesInfo = (lineId, { getDevicesInfo }, dispatch) => {
  if (lineId > 0) {
    getDevicesInfo(lineId).then(({ data }) => {
      dispatch(setLineInfo(data))
    })
  }
}

export const SET_SELECTED_LINE = 'SET_SELECTED_LINE';
export const setSelectedLine = create_action_creator(SET_SELECTED_LINE);

//установк видимости карты
export const SET_STORE_PARAMS = 'SET_STORE_PARAMS';
export const setStoreParams = create_action_creator(SET_STORE_PARAMS);
export const CLEAR_STORE_PARAMS = 'CLEAR_STORE_PARAMS';
export const clearStoreParams = create_action_creator(CLEAR_STORE_PARAMS);

//получить все регионы
export const SET_AREAS = 'SET_AREAS';
const setAreas = create_action_creator(SET_AREAS);

const fetchAreas = ({ getAreas }) => (dispatch) => {
  getAreas().then(({ data }) => {
    dispatch(setAreas(data))
  })

  // try {
  //   // установить состояние загрузки (dispatch loading action)
  //   // получить данные с сервера
  //   // dispatch success action
  //  } catch {
  //   //  если произошла ошибка dispatch error action
  //  }
}

// const fetchAreas = ({ getAreas }) => () => (dispatch) => {
//   getAreas().then(({ data }) => {
//     dispatch(setAreas(data))
//   })
// }

// const fetchAreas = ({ getAreas }, dispatch) => {
//   getAreas().then(({ data }) => {
//     dispatch(setAreas(data))
//   })
// }

export const SET_SELECTED_AREA = 'SET_SELECTED_AREA';
export const setSelectedArea = create_action_creator(SET_SELECTED_AREA);

//настройки для страницы отчетов
export const SET_PARAM = 'SET_PARAM';
const setParam = create_action_creator(SET_PARAM);

export const SET_REPORT_USPD = 'SET_REPORT_USPD';
export const setReportUspd = create_action_creator(SET_REPORT_USPD);
export const CLEAR_REPORT_USPD = 'CLEAR_REPORT_USPD';
export const clearReportUspd = create_action_creator(CLEAR_REPORT_USPD);

const fetchReportUspd = (uspdNumber, from, to, regimes, { getReportLine }, dispatch) => {
  dispatch(setParam({
    loading: true,
    data: undefined
  }))

  getReportLine(uspdNumber, {
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD'),
    regime: regimes,
  }).then(({ data }) => {
    dispatch(setReportUspd(data))
  }
  );
}

//параметры отчета
export const SET_REPORT_PARAMS = 'SET_REPORT_PARAMS';
export const setReportParams = create_action_creator(SET_REPORT_PARAMS);


export const SET_REPORT = 'SET_REPORT';
export const setReport = create_action_creator(SET_REPORT);

export const CLEAR_REPORT_PARAMS = 'CLEAR_REPORT_PARAMS';
export const clearReportParams = create_action_creator(CLEAR_REPORT_PARAMS);

export const SET_TYPE_REPORT = 'SET_TYPE_REPORT';
export const setTypeReport = create_action_creator(SET_TYPE_REPORT);

const fetchReport = ({ getReport, getViolations }, currentReport, reportAreas, reportTypes, from, to, sortingBy) => (dispatch) => {
  dispatch(setReportParams({
    typeReport: currentReport,
    settings: {
      loading: true,
      data: undefined
    }
  }))
  console.log('fetchReport currentReport', currentReport)
  console.log('fetchReport sortingBy.key', sortingBy.key)

  let regime = (currentReport === 'dispatch') ? 7 : 4 ;

  if (currentReport === 'violation') {
    getViolations(sortingBy.key, {
      reportAreas: reportAreas,
      reportTypes: reportTypes,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
    }).then(({ data }) => {
      // console.log("full report data", data)
      dispatch(setReport({
        typeReport: currentReport,
        dataReport: data
      }))
    }
    );
  } else {
    getReport(sortingBy.key, {
      reportAreas: reportAreas,
      reportTypes: reportTypes,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD'),
      regime: regime,
    }).then(({ data }) => {
      // console.log("full report data", data)
      dispatch(setReport({
        typeReport: currentReport,
        dataReport: data
      }))
    }
    );
  }
}




export const SET_ALL_COMPLEXES = 'SET_ALL_COMPLEXES';
const setAllComplexes = create_action_creator(SET_ALL_COMPLEXES);

export const SET_COMPLEXES = 'SET_COMPLEXES';
const setComplexes = create_action_creator(SET_COMPLEXES);

const fetchAllComplexes = ({ getAllComplexes }, dispatch) => () => {
  getAllComplexes().then((data) => {
    // console.log('fetchAllComplexes', data);
    dispatch(setAllComplexes(data));
  })
}

const fetchComplexes = (areas, { getComplexes }, dispatch) => {
  getComplexes(areas).then((data) => {
    // console.log('fetchComplexes', data);
    dispatch(setComplexes(data));
  })
}

export {
  fetchAreas,
  fetchFactorys,
  fetchAllComplexes,
  fetchComplexes,
  setParam,
  fetchTypesFactorys,
  fetchLines,
  fetchDetails,
  fetchModules,
  fetchFactoryComplexes,
  fetchDevices,
  fetchDevicesInfo,
  fetchReportUspd,
  fetchFactoryInformation,
  fetchReport,
};
import moment from 'moment';
import { createSelector } from 'reselect'

import {
  SET_REPORT_PARAMS,
  SET_REPORT,
  CLEAR_REPORT_PARAMS,
  SET_TYPE_REPORT
} from '../actions';

import {
  reduceAdd,
} from './reducers';


const initialStruct = {
  currentStep: 1,

  selectedAreas: [],
  selectedTypes: [],
  sortingBy: { key: 'areas', text: 'По регионам', value: 'areas' },
  from: moment().subtract(7, 'days').toDate(),
  to: moment().toDate(),

  reportAreas: [],
  reportTypes: [],
  reportSort: null,
  reportFrom: null,
  reportTo: null,

  data: undefined,
  loading: false,
}

const initialState = {
  currentIdx: 'produce',
  settings: {
    produce: initialStruct,
    dispatch: initialStruct,
    violation: initialStruct,
  }
}

export const report = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_PARAMS: {
      let settings = state.settings;
      settings[action.payload.typeReport] = reduceAdd(settings[action.payload.typeReport], action.payload.settings)
      return {
        ...state,
        settings: settings
      }
    }
    case SET_TYPE_REPORT: {
      return {
        ...state,
        currentIdx: action.payload,
      }
    }
    case CLEAR_REPORT_PARAMS:
      return {
        currentIdx: 'produce',
        settings: {
          produce: initialStruct,
          dispatch: initialStruct,
          violation: initialStruct,
        }
      };


    case SET_REPORT: {
      const type = action.payload.typeReport
      let settings = state.settings;
      settings[type].data = action.payload.dataReport;
      settings[type].loading = false;
      settings[type].reportAreas = state.settings[type].selectedAreas;
      settings[type].reportTypes = state.settings[type].selectedTypes;
      settings[type].reportSort = state.settings[type].sortingBy;
      settings[type].reportFrom = state.settings[type].from;
      settings[type].reportTo = state.settings[type].to;

      return {
        ...state,
        settings,
      }
    }

    default:
      return state;
  };
}

const getSettings = ({ report }) => report.settings[report.currentIdx]

export const getSelectedAreas = createSelector(
  [getSettings],
  (settings) => settings.selectedAreas
)

// export const getSelectedAreas = ({ report }) => {
//   return report.settings[report.currentIdx].selectedAreas;
// }

export const getSelectedTypes = ({ report }) => {
  return report.settings[report.currentIdx].selectedTypes;
}

export const getReportAreas = ({ report }) => {
  return report.settings[report.currentIdx].reportAreas;
}

export const getReportTypes = ({ report }) => {
  return report.settings[report.currentIdx].reportTypes;
}

export const getSortingType = ({ report }) => {
  return report.settings[report.currentIdx].sortingBy;
}

export const getReportSortingType = ({ report }) => {
  return report.settings[report.currentIdx].reportSort;
}

export const getCurrentStep = ({ report }) => {
  return report.settings[report.currentIdx].currentStep;
}

export const getInterval = ({ report }) => {
  // console.log('getInterval', report)
  return {
    from: report.settings[report.currentIdx].from,
    to: report.settings[report.currentIdx].to
  }
}

export const getReportInterval = ({ report }) => {
  return {
    from: report.settings[report.currentIdx].reportFrom,
    to: report.settings[report.currentIdx].reportTo
  }
}

export const getData = ({ report }) => {
  return report.settings[report.currentIdx].data;
}

export const getLoading = ({ report }) => {
  return report.settings[report.currentIdx].loading;
}

export const getTypeReport = ({ report }) => {
  return report.currentIdx;
}
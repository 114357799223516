import React from 'react';
import { Menu } from 'semantic-ui-react';
import './page-menu-current.css';

const PageMenuCurrent = props => {
    const { activeItem, children: panes, onClickItem } = props;

    return (
        <div className='content-computer'>
            <Menu pointing secondary color='blue'>
                {Object.keys(panes).map(key => (
                    <Menu.Item
                        name={key}
                        key={key}
                        className={key}
                        active={key === activeItem}
                        onClick={onClickItem(key)}
                    >
                        {panes[key].label}
                    </Menu.Item>
                ))}
            </Menu>
        </div>

    );
};

export default PageMenuCurrent;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../views/spinner';
import { LineDetails } from '../views';


import * as linesReducer from '../../redux/reducers/reduce-lines';

class LineContainer extends Component {

  render() {
    const { details,
      loading,
      selectedLine,
      lineId,
      lineInfo
    } = this.props;

    // console.log("lineInfo", lineInfo)

    if (loading || lineInfo.length < 1) {
      return <Spinner />
    } else if (selectedLine === lineId) {
      return (
      <div>
        <LineDetails line={details} />
        {/* <DevicesInfo info={lineInfo} /> */}
      </div>)
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  const { details, selectedLine } = linesReducer.getDetails(state);
  return {
    details,
    selectedLine,
    loading: linesReducer.getLoading(state),
    lineInfo: linesReducer.getLineInfo(state),
  }
}

export default
  connect(
    mapStateToProps
  )(LineContainer)
import React, { Component } from 'react';
import { Provider } from 'react-redux';
// import AppServiceTest from '../../services/app-service-test';
import AppService from '../../services/app-service';
import { APIProvider } from '../api'
import ErrorBoundry from '../error-boundry';


import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import NavBar from '../navbar';
import MonitorPage from '../pages/monitor-page';
import LoginPage from '../pages/login-page';
import ReportPage from '../pages/report-page';

import store from '../../store';

import './app.css'

const { isAuth } = new AppService();

const LoginRoute = ({ component: Component, ...rest }) => {
  return (<Route exact {...rest} render={props => <Component {...props} />} />)
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (<Route
    {...rest}
    render={props => {
      if (isAuth()) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        );
      }
    }}
  />)
};


class App extends Component {

  appService = new AppService();

  render() {
    console.log("APPPPPP")
    return (
      <Provider store={store}>
        <ErrorBoundry>
          <APIProvider value={this.appService}>
            <Router>
              <Route
                path='/'
                render={({ location }) => (
                  <div>
                    <NavBar location={location} />
                    {/* <GMap /> */}
                  </div>
                )}
              />
              <Switch>
                <LoginRoute
                  path='/login'
                  component={LoginPage}
                />
                <PrivateRoute path="/" component={MonitorPage} exact/>
                <PrivateRoute path="/reports" component={ReportPage} exact />
                {/* <PrivateRoute path="/monitoring" component={MmonitorPage} exact /> */}
              </Switch>
            </Router>
          </APIProvider>
        </ErrorBoundry>
      </Provider>
    )
  }
}

export default App;
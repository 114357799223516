import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from '../../utils';
import withAPI from '../hoc/with-api';
import moment from 'moment';

import {
  Accordion, Icon, Header
} from 'semantic-ui-react';

import { GetReportButtons } from '../views';
import {
  setReportParams,
  fetchReport,
} from '../../redux/actions';

import './report-settings.css';

import ReportAreasContainer from "../containers/report-areas-container";
import ReportTypesContainer from '../containers/report-types-container';
import ReportSortContainer from '../containers/report-sort-container';
import ReportIntervalContainer from '../containers/report-interval-container';

import * as reportReducer from '../../redux/reducers/reduce-report';


var StepEnum = { "setArea": 1, "setType": 2, "setProduct": 3, "setDate": 4, "setSort": 5 }
Object.freeze(StepEnum)

class ReportSettings extends Component {

  state = {
    currentStep: StepEnum.setArea,
  }

  isDisabled = () => {
    const { reportAreas, reportTypes } = this.props;
    return reportAreas.length === 0 || reportTypes.length === 0
  }

  getData = () => {
    const { reportAreas, reportTypes, from, to, sortingBy, fetchReport, currentReport } = this.props;
    fetchReport(currentReport, reportAreas, reportTypes, from, to, sortingBy);
  }

  handleClick = (e, titleProps) => {
    const { currentStep } = this.state;
    var { index } = titleProps
    if (currentStep === index) {
      index = -1
    }
    this.setState({ currentStep: index })
    const { setReportParams, currentReport } = this.props;
    setReportParams({
      typeReport: currentReport,
      settings: { currentStep: index }
    })
  }

  clearSettings = () => {
    const { setReportParams, currentReport } = this.props;
    setReportParams({
      typeReport: currentReport,
      settings: {
        sortingBy: { key: 'areas', text: 'По регионам', value: 'areas' },
        selectedAreas: [],
        selectedTypes: [],
        from: moment().subtract(7, 'days').toDate(),
        to: moment().toDate(),
      }
    })
  }

  accordionTitle = (step, text) => {
    const { currentStep } = this.props;
    return (
      <Accordion.Title
        active={currentStep === step}
        index={step}
        onClick={this.handleClick}
      >
        <Icon name='dropdown' />
        {text}
      </Accordion.Title>
    )
  }

  render() {
    const { currentStep, currentReport } = this.props;
    return (
      <div className='report-setting'>
        <Header as='h4' textAlign='center' >Настройки отчета</Header>
        <Accordion styled fluid className='report-setting-accordion'>
          {this.accordionTitle(StepEnum.setArea, 'Выберите регион')}
          <Accordion.Content active={currentStep === StepEnum.setArea}>
            <ReportAreasContainer />
          </Accordion.Content>

          {this.accordionTitle(StepEnum.setType, 'Выберите тип предприятия')}
          <Accordion.Content active={currentStep === StepEnum.setType}>
            <ReportTypesContainer
              type={currentReport} />
          </Accordion.Content>

          {this.accordionTitle(StepEnum.setDate, 'Выберите период отчета')}
          <Accordion.Content active={currentStep === StepEnum.setDate}>
            <ReportIntervalContainer
              type={currentReport} />
          </Accordion.Content>

          {this.accordionTitle(StepEnum.setSort, 'Сортировать по ')}
          < Accordion.Content active={currentStep === StepEnum.setSort}>
            <ReportSortContainer
              type={currentReport} />
          </Accordion.Content>
        </Accordion>

        <GetReportButtons
          loadDisabled={true}
          getDisables={this.isDisabled()}
          // getFile={getFile}
          clearSettings={this.clearSettings}
          getData={this.getData}
        />
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  const { from, to } = reportReducer.getInterval(state);
  return {
    from, to,
    currentStep: reportReducer.getCurrentStep(state),
    reportAreas: reportReducer.getSelectedAreas(state),
    reportTypes: reportReducer.getSelectedTypes(state),
    sortingBy: reportReducer.getSortingType(state),
    currentReport: reportReducer.getTypeReport(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    setReportParams: params => dispatch(setReportParams(params)),
    fetchReport: (currentReport, reportAreas, reportTypes, from, to, sortingBy) =>
      dispatch(fetchReport(api, currentReport, reportAreas, reportTypes, from, to, sortingBy)),
  }

  // return bindActionCreators({
  //   setReportParams: setReportParams,
  //   fetchReport: fetchReport(api),
  // }, dispatch);
}

export default compose(
  withAPI(),
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(ReportSettings);

import React from 'react';
import { List, Checkbox } from 'semantic-ui-react';

import './report-param-list.css';

const ReportParamList = ({ list, reportList, setParams, label, nameKey }) => {

  const createNewList = (oldList, value) => {
    let newList = [];
    const idx = oldList.findIndex(item => item === value);
    if (idx === -1) {
      newList = [...oldList, value];
    } else {
      newList = [...oldList.slice(0, idx), ...oldList.slice(idx + 1)];
    }
    return newList;
  }

  const handleChange = (e, { value }) => {
    let newList = [];
    if (value === -1) {
      if (list.length !== reportList.length) {
        if (list) {
          list.map(({ id }) => (
            newList = [...newList, id]
          ))
        }
      }
    } else {
      newList = createNewList(reportList, value);
    }
    setParams(newList);
  }

  return (
    <List divided relaxed className='report-param-list'>
      <List.Item key={`${nameKey}${-1}`}>
        <Checkbox
          key={`${nameKey}${-1}`}
          id={`${nameKey}${-1}`}
          value={-1}
          label={label}
          checked={Array.isArray(reportList) && list.length === reportList.length}
          onChange={handleChange}
        />
      </List.Item>

      {list.map(({ id, name }) => {
        return (
          <List.Item key={id}>
            <Checkbox
              key={`${nameKey}${id}`}
              id={`${nameKey}${id}`}
              value={id}
              label={name}
              checked={Array.isArray(reportList) && reportList.findIndex(item => item === id) > -1}
              onChange={handleChange}
            />
          </List.Item>
        )
      })}
    </List>
  )
}

export default ReportParamList;
import React from 'react';
import { Accordion, Menu } from 'semantic-ui-react';
import { LineInfo } from '../../views';
import LineContainer from '../../containers/line-container';

import './lines-list.css';

const LinesList = props => {
  const { lines, uspdNumber, selectedLine, handleClick } = props;
  return (
    <div key={uspdNumber}>
      {'УСПД: ' + uspdNumber}
      <Menu.Item
        key={uspdNumber}
      >
        <Accordion fluid className='line-list-accordion'>
          {Array.isArray(lines) && (
            lines.map(line => {
              const isActive = (selectedLine === line.lineId);

              return (
                <div key={line.lineId} >
                  <Accordion.Title
                    active={isActive}
                    index={line.lineId}
                    onClick={handleClick(line.lineId)}
                  >
                    <LineInfo
                      line={line}
                      isActive={isActive}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={isActive}>
                    <LineContainer lineId={line.lineId} />
                  </Accordion.Content>
                </div>
              )

            })
          )}
        </Accordion>
      </Menu.Item>
    </div>
  )
}

export default LinesList;
import {
  SET_FACTORY_INFORMATION,
} from '../actions';

import {
  reduceAdd,
} from './reducers';

const initialState = {
  information: undefined,
  employee: undefined,
  versions: [],
}

export const information = (state = initialState, action) => {
  switch (action.type) {
    case SET_FACTORY_INFORMATION:
      return reduceAdd(state, action.payload);

    default:
      return state;
  }
}

export const getInformation = ({ information }) => {
  return information.information;
}

export const getEmployee = ({ information }) => {
  return information.employee;
}

export const getVersions = ({ information }) => {
  return information.versions;
}

import {
  SET_DEVICES,
  SET_SELECTED_USPD,
} from '../actions';

const initialState = {
  lines: [],
  selectedUspd: null,
  loading: true,
}

export const devices = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICES:
      return {
        ...state,
        lines: action.payload,
        loading: false
      }
    case SET_SELECTED_USPD:
      return {
        selectedUspd: action.payload,
        lines: [],
        loading: true
      }

    default:
      return state;
  };
};

export const getDevices = ({ devices }) => {
  return {
    lines: devices.lines,
    selectedUspd: devices.selectedUspd
  }
}

export const getLoading = ({ devices }) => {
  return devices.loading
} 
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReportParamList } from '../views'
import { compose } from '../../utils';
import withAPI from '../hoc/with-api';

import {
  fetchTypesFactorys,
  setReportParams,
} from '../../redux/actions';

import * as factoryReducer from '../../redux/reducers/reduce-factorys';
import * as reportReducer from '../../redux/reducers/reduce-report';

class ReportTypesContainer extends Component {

  componentDidMount() {
    const { fetchTypesFactorys } = this.props;
    fetchTypesFactorys();
  }

  setParams = (newList) => {
    console.log('setParams types', newList)
    const { setReportParams, type } = this.props;
    setReportParams({
      typeReport: type,
      settings: { selectedTypes: newList }
    })

  }

  render() {
    const { factoryTypes, reportTypes } = this.props;

    return (
      <ReportParamList key='types-list'
        list={factoryTypes}
        reportList={reportTypes}
        setParams={this.setParams}
        label={'Все типы предприятий'}
        nameKey={'types'}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    factoryTypes: factoryReducer.getFactorysTypes(state),
    reportTypes: reportReducer.getSelectedTypes(state),
  }
}

const mapDispatchToProps = (dispatch, { api }) => {
  return {
    fetchTypesFactorys: _ => dispatch(fetchTypesFactorys(api)),
    setReportParams: params => dispatch(setReportParams(params)),
  }
}

export default compose(
  withAPI(),
  connect(mapStateToProps, mapDispatchToProps)
)(ReportTypesContainer);
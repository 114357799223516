import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';


import { DataVisual, DataTable } from '../views';
import Spinner from '../views/spinner';

import * as deviceReducer from '../../redux/reducers/reduce-devices';


class DeviceContainer extends Component {
  createList = (lines) =>
    Array.isArray(lines) && (
      lines.map(device => {
        return (
          <div key={device.pointOfControl}>
            <Header className='line-name' as='h4' >{device.lineName} ({device.uspdNumber}_{device.pointOfControl})</Header>
            <DataVisual visual={DataTable} data={device.devices} />
          </div>
        )
      })
    )

  render() {
    const { lines,
      loading,
      selectedUspd,
      uspd: { uspdNumber } } = this.props;

      if (loading) {
      return <Spinner />
    } else if (uspdNumber === selectedUspd) {
      return this.createList(lines)
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => {
  const { lines, selectedUspd } = deviceReducer.getDevices(state);
  return {
    lines,
    selectedUspd,
    loading: deviceReducer.getLoading(state)
  }
}

export default
  connect(
    mapStateToProps
  )(DeviceContainer);
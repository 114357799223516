import React, { Component } from 'react';
import { GridMenu, PageMenuCurrent } from '../../views';

import ProductionReport from '../production-report';

import './report-page.css'
import ReportSettings from '../../report-settings';

var TypeReport = { "produce": 1, "dispatch": 2, "violation": 3 }
Object.freeze(TypeReport)

class ReportPage extends Component {

  state = {
    activeTab: null,
    isVisibleSettings: false,
  }

  basicTabs = {
    produce: {
      label: 'По производству',
      type: 'produce',
      mobile: true,
    },
    dispatch: {
      label: 'По отгрузкам',
      type: 'dispatch',
      mobile: true,
    },
    violation: {
      label: 'По нарушениям',
      type: 'violation',
      mobile: true,
    },

  };

  componentDidMount() {
    if (!this.state.activeTab && Object.keys(this.basicTabs).length)
      this.clickMenuItem(Object.keys(this.basicTabs)[0])();
  }

  clickMenuItem = name => () => this.setState({ activeTab: name })
  viewContent = (basicTabs, activeItem) => basicTabs[activeItem] ? <ProductionReport type={basicTabs[activeItem].type} /> : <div />;

  render() {
    const { activeTab } = this.state;

    const currentData = (
      <div className='current-data'>
        <PageMenuCurrent
          activeItem={activeTab}
          onClickItem={this.clickMenuItem}
        >
          {this.basicTabs}
        </PageMenuCurrent>
        <div className='content-tab'>
          {this.viewContent(this.basicTabs, activeTab)}
        </div>
      </div>
    )

    return (
      <GridMenu
        activeTab={activeTab}
        clickMenuItem={this.clickMenuItem}
        basicTabs={this.basicTabs}
        settings={<ReportSettings />}
        currentData={currentData}
        label='Настройки'>
      </GridMenu>
    )
  }
}

export default ReportPage;
import React from 'react';
import './data-visual.css';

import Spinner from '../spinner';
import EmptyData from '../data-empty';

const isEmpty = obj => Object.getOwnPropertyNames(obj).length === 0;

const DataVisual = ({ visual, data, footer }) => {
    switch (data) {
        case null:
            return <EmptyData />;
        case undefined:
        case isEmpty(data):
            return <Spinner />;
        default:
            if (data.length === 0) return <EmptyData />;
    }
    return visual(data, footer);
};

export default DataVisual;

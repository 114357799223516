import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from '../../utils';
import withAPI from '../hoc/with-api';
import { fetchFactorys, setFactorys } from '../../redux/actions';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import CurrLocation from '../map/curr-location';

import * as factoryReducer from '../../redux/reducers/reduce-factorys';
import * as paramReducer from '../../redux/reducers/reduce-params';
import * as areasReducer from '../../redux/reducers/reduce-areas';


import './map.css'

const writeLog = (props) => {
    // console.log("w complexes", props);
}

const Map = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={6}
            // zoom={6}
            defaultCenter={props.center}
            center={props.center}
            draggable={true}
            defaultOptions={{
                streetViewControl: false,
                scaleControl: true,
                mapTypeControl: false,
                panControl: false,
                zoomControl: true,
                rotateControl: false,
                fullscreenControl: true
            }}
        >
            {writeLog(props)}
            {props.factorys != null && (
                <CurrLocation
                    factorys={props.factorys}
                    selectedFactory={props.selectedFactory}
                    onClickItem={props.onClickItem}
                />
            )
            }
        </GoogleMap>
    ))
);

class GMap extends Component {

    render() {
        const { factorys, selectedFactory, setFactory, isVisibleMap, center } = this.props;

        var mapVisibility = isVisibleMap ? 'visible' : 'hidden';
        var mapHeight = isVisibleMap ? '45vh' : '0vh';

        return (
            <div className='map' style={{ visibility: mapVisibility }}>
                <Map
                    //     Если вдруг будут какие то проблемы с google картой (Например: for development purposes only), то
                    // видимо есть какие то проблемы с API ключом (Пример: ?key=AIzaSyBvEDqr7lod9Xo5432gjjgt8cymrpOYmag).
                    // Нужно будет зайти в Google APIs и возможно получить новый ключ. Или дополнительно загуглить эту проблему. 
                    googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBvEDqr7lod9Xo5432gjjgt8cymrpOYmag'
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={<div style={{ height: mapHeight }} />}
                    mapElement={<div style={{ height: '100%' }} />}
                    factorys={factorys}
                    selectedFactory={selectedFactory}
                    onClickItem={setFactory}
                    center={center}
                />
                {/* заглушка для MAP.
            Во время разработки при каждой перезагрузке приложения тратится лимит запросов к API.
            Эта заглушка экономит лимит. Можно закомментировать компонент Map (вверху), а div (внизу) раскомментировать.  */}
                {/* <div className='map-fake' style={{ height: mapHeight, backgroundColor: 'grey' }}>MAP</div> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        factorys: factoryReducer.getFactorys(state),
        selectedFactory: factoryReducer.getSelected(state),
        isVisibleMap: paramReducer.getStatusMap(state),
        center: areasReducer.getCenter(state),
    }
}

const mapDispatchToProps = (dispatch, { api }) => {
    return {
        fetchFactorys: fetchFactorys(api, dispatch),
        setFactory: id => dispatch(setFactorys({ selectedFactory: id })),
    }
}

export default compose(
    withAPI(),
    connect(
        mapStateToProps,
        mapDispatchToProps)
)(GMap);
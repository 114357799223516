import {
  SET_LINES,
  SET_DETAILS,
  SET_SELECTED_LINE,
  SET_LINEINFO,
} from '../actions';

const initialState = {
  lines: [],
  details: [],
  lineInfo: [],
  loading: true,
  selectedLine: null
}

export const lines = (state = initialState, action) => {
  switch (action.type) {
    case SET_LINES:
      return {
        ...state,
        lines: action.payload,
      }
    case SET_DETAILS:
      return {
        ...state,
        details: action.payload,
        loading: false
      }
    case SET_LINEINFO:
      return {
        ...state,
        lineInfo: action.payload,
      }
    case SET_SELECTED_LINE:
      return {
        ...state,
        selectedLine: action.payload,
        lineInfo: [],
        details: [],
        loading: true
      }
    default:
      return state;
  };
};

export const getLines = ({ lines }) => {
  return lines.lines
}

export const getDetails = ({ lines }) => {
  return {
    details: lines.details,
    selectedLine: lines.selectedLine
  }
}

export const getLineInfo = ({ lines }) => {
  return lines.lineInfo
} 

export const getLoading = ({ lines }) => {
  return lines.loading
} 
import {
  SET_MODULES,
  SET_SELECTED_USPD
} from '../actions';

const initialState = {
  modules: [],
  selectedUspd: null,
  loading: true,
}

export const modules = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODULES:
      return {
        ...state,
        modules: action.payload,
        loading: false
      }
    case SET_SELECTED_USPD:
      return {
        loading: true,
        selectedUspd: action.payload,
        modules: [],
      }

    default:
      return state;
  };
};


export const getModules = ({ modules }) => {
  return  { 
    modules: modules.modules, 
    selectedUspd: modules.selectedUspd 
  }
}

export const getLoading = ({ modules }) => {
  return modules.loading
} 
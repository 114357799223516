import React from 'react';
import { Grid } from 'semantic-ui-react';

import './page-visual.css';

const PageVisual = ({ page, firstColumn, secondColumn }) => {
    return (
        // <React.Fragment>
        //     <div className="ui stackable sixteen column grid">
        //         <div className="row">
        //             <div className="five wide column">{firstColumn}</div>
        //             <div className="eleven wide column">{secondColumn}</div>
        //         </div>
        //     </div>
        // </React.Fragment>

        <React.Fragment>
            <Grid stackable columns={2}>
                <Grid.Column className={`${page} one`}>
                    {firstColumn}
                </Grid.Column>
                <Grid.Column className={`${page} two`}>
                    {secondColumn}
                </Grid.Column>
            </Grid>
        </React.Fragment>


        // <React.Fragment>
        //     <div class="ui mobile reversed equal width grid">
        //         <div class="column">{firstColumn}</div>
        //         <div class="column">{secondColumn}</div>
        //     </div>
        // </React.Fragment>


        // <React.Fragment>
        //     <div class="ui stackable four column grid">
        //         <div className={`${page} one`} class="column">{firstColumn}</div>
        //         <div className={`${page} two`} class="column">{secondColumn}</div>
        //     </div>
        // </React.Fragment>



    );
};

export default PageVisual;

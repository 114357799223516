import React from 'react';

import { Table, Grid, Divider } from 'semantic-ui-react';
import './line-details.css';

import { DataVisual } from '../index';

const viewValue = value => (value !== undefined ? value.toFixed(2) : 'NaN');

const mobileDetails = (details) => (
  <Table className='first-mobile' definition compact celled unstackable>
    <Table.Body>
      {Object.keys(details).map(key => (
        <Table.Row key={key}>
          <Table.Cell>{key}</Table.Cell>
          <Table.Cell>{details[key]}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

const computerDetails = (details) => (
  <Table className='first-computer' celled unstackable>
    <Table.Header>
      <Table.Row>
        {Object.keys(details).map(key => (
          <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        {Object.keys(details).map(key => (
          <Table.Cell key={details[key]}>{details[key]}</Table.Cell>
        ))}
      </Table.Row>
    </Table.Body>
  </Table>
)

const details = ({
  alko_strength_curr,
  alko_volume_100,
  volume,
  alko_volume,
  bottles_counts,
  aver_alko_strength,
  over_alko_volume,
  over_alko_volume_100,
  over_bottles_counts,
  total
}) => {

  const volumeDetails = {
    "Объем": viewValue(volume),
    "Объем 20°C": viewValue(alko_volume),
    "Объем безв. 20°C": viewValue(alko_volume_100)
  }
  const percentageDetails = {
    "Крепость": viewValue(alko_strength_curr),
    "Средняя крепость": viewValue(aver_alko_strength),
    "Бутылки": bottles_counts
  }

  const volumeAccum = {
    "Объем 20°C": viewValue(over_alko_volume),
    "Объем безв. 20°C": viewValue(over_alko_volume_100)
  }

  const totalAccum = {
    "Тоталайзер": viewValue(total),
    "Бутылки": over_bottles_counts
  }
  return (

    <Grid stackable className='details' >
      <Grid.Column className='current'>
        <Divider horizontal>Текущие данные</Divider>
        <Grid.Row>
          {computerDetails(volumeDetails)}
          {mobileDetails(volumeDetails)}
        </Grid.Row>
        <Grid.Row>
          {computerDetails(percentageDetails)}
          {mobileDetails(percentageDetails)}
        </Grid.Row>
      </Grid.Column>
      <Grid.Column className='accumulated'>
        <Divider horizontal>Накопленные данные</Divider>
        <Grid.Row>
          {computerDetails(volumeAccum)}
          {mobileDetails(volumeAccum)}
        </Grid.Row>
        <Grid.Row>
          {computerDetails(totalAccum)}
          {mobileDetails(totalAccum)}
        </Grid.Row>
      </Grid.Column>
    </Grid >
  )
};

const lineDetails = line => (
  <div className='line-content'>
    <DataVisual visual={details} data={line} />
  </div>
);

export default ({ line }) => <DataVisual visual={lineDetails} data={line} />;

import React from 'react';

import { List, Radio } from 'semantic-ui-react';

import './report-sorting.css'

const ReportSorting = ({ sortingBy, handleChange, sortOptions }) => {
  const handleChangeSorting = (e, { value }) =>
    handleChange(sortOptions.find((item) => item.value === value))

  return (
    <List divided relaxed className="sorting-types-list">
      {sortOptions.map((item) => {
        return (
          <List.Item key={item.key}>
            <Radio className='radio-type'
              label={item.text}
              name={item.key}
              value={item.value}
              checked={sortingBy.key === item.key}
              onChange={handleChangeSorting}
            />
          </List.Item>
        )
      })}
    </List>
  )
}

export default ReportSorting;
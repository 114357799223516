import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import './calendar.css';

import { 
  setParam, 
} from '../../redux/actions';

import DateSelector from './date';

class CalendarInterval extends Component {
  funcFrom = (date) => {
    this.props.onGetFrom(date)
  }

  funcTo = (date) => {
    this.props.onGetTo(date)
  }

  render() {
    const { uspdReport: { from, to }, children, title } = this.props;
    return(
      <Menu className='date-selector'>
        {title ? <Menu.Item>&nbsp;<h5>{title}</h5>&nbsp;</Menu.Item> : null}
        <Menu.Item><DateSelector className='from' func={this.funcFrom} date={from} /></Menu.Item>
        <Menu.Item>-</Menu.Item>
        <Menu.Item><DateSelector className='to' func={this.funcTo} date={to} /></Menu.Item>
        {children ? <Menu.Item position='right'>{children}</Menu.Item> : null}
      </Menu>
    );
  };
};

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
  return {
    onGetFrom: from => dispatch(setParam({ from })),
    onGetTo: to => dispatch(setParam({ to }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarInterval);
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, Image, Icon, Dropdown } from 'semantic-ui-react';
import logo from '../../images/favicon.ico';
import { connect } from 'react-redux';
import { compose } from '../../utils';
import withAPI from '../hoc/with-api';
import { Redirect } from 'react-router-dom';

import {
  setStoreParams,
  clearStoreParams,
  clearReportParams,
  clearReportUspd,
} from '../../redux/actions';
import * as paramsReducer from '../../redux/reducers/reduce-params';

import './navbar.css';

class NavBar extends Component {

  componentDidMount() {
    const { api, setLogout } = this.props;
    this.timerLogout = setInterval(() =>
      api.isAuth()
        ? api.isLogout()
          .then(({ data }) =>
            setLogout(data)
          )
        : setLogout(false),
      10000
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timerLogout);
  }

  clearStore = () => {
    console.log('clearStore')
    const { clearStoreParams, clearReportParams, clearReportUspd } = this.props;
    clearStoreParams();
    clearReportParams();
    clearReportUspd();
  }

  render() {
    const { location, isLogout } = this.props;
    if (isLogout) {
      this.clearStore()
      return <Redirect to='/login' />;
    }
    if (location.pathname === '/login') return <div />;

    const ButtonLogout = () => {
      console.log('ButtonLogout')

      return (
        <Link to='/login'>
          <Button animated='fade' basic
            onClick={this.clearStore}>
            <Button.Content visible>
              <Icon name='sign-out' />
            </Button.Content>
            <Button.Content hidden>Выход</Button.Content>
          </Button>
        </Link>
      )
    };

    const ButtonMenu = () => {
      return (
        <Menu secondary className='button-menu'>
          <Menu.Menu position='right'>
            <Dropdown 
              item
              icon='ellipsis horizontal'
            >
              <Dropdown.Menu className='dd-button-menu'>
                <Dropdown.Item>
                  <Link to="/" >
                    Мониторинг
                 </Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Link to="/reports" >
                    Отчетность
                 </Link>
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item>
                  <ButtonLogout />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      )
    };

    return (
      <React.Fragment>
        <Menu secondary className='navbar'>
          <Menu.Item className='logo'>
            <Link to='/'>
              <Image src={logo} className='empty' />
            </Link>
          </Menu.Item>
          <Menu.Item className='label'>
            <h3>Мониторинг Узбекистан</h3>
          </Menu.Item>

          <Menu.Item className='choice-tab' position='right' >

            <Button.Group >
              <Link to="/" >
                <Button className={location.pathname === "/" ? 'select-button' : 'unselect-button'}>
                  Мониторинг
            </Button>
              </Link>
              <Link to="/reports" >
                <Button className={location.pathname === '/reports' ? 'select-button' : 'unselect-button'}>
                  Отчетность
            </Button>
              </Link>
            </Button.Group >
          </Menu.Item>

          <Menu.Item className='logout-action' >
            <ButtonLogout />
          </Menu.Item>

          <Menu.Item className='button-menu' position='right' >
            <ButtonMenu />
          </Menu.Item>

        </Menu>
      </React.Fragment>
    );
  }
}


NavBar.defaultProps = {
  view: true
};

const mapStateToProps = state => {
  return {
    isLogout: paramsReducer.getIsLogout(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLogout: isLogout => dispatch(setStoreParams({ isLogout: isLogout })),
    clearStoreParams: _ => dispatch(clearStoreParams()),
    clearReportParams: _ => dispatch(clearReportParams()),
    clearReportUspd: _ => dispatch(clearReportUspd()),
  }
}

export default compose(
  withAPI(),
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(NavBar);
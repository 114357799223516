import { createStore, applyMiddleware } from 'redux';
import createRootReducer from  './redux/reducers';
import thunk from 'redux-thunk';

const logEnhancer = (createStore) => (...args) => {
    const store = createStore(...args);
    const originDispatch = store.dispatch;
    store.dispatch = (action) => {
        console.log(action.type);
        return originDispatch(action);
    }
    return store;
}

const store = createStore(
    createRootReducer(),
    applyMiddleware(thunk),
);

export default store;
import React from 'react';

import { Button } from 'semantic-ui-react';

import './get-report-buttons.css';

const GetReportButtons = ({
  loadDisabled,
  getDisables,
  getFile,
  clearSettings,
  getData }) => {

  return (
    <div className="get-report-buttons">
      <Button.Group widths='3'>
        <Button className='clear-button' basic color='blue'
          onClick={clearSettings}
        >
          Сбросить
    </Button>
        <Button className='get-button'
          color='blue'
          disabled={getDisables}
          onClick={getData}
        >
          Сформировать
    </Button>
        <Button className='download-button'
          positive
          icon='download'
          disabled={loadDisabled}
          onClick={getFile}
        />
      </Button.Group>
    </div>
  )
}

export default GetReportButtons;
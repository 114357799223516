import React, { Component } from 'react';

import {
  Segment, Form, Accordion, Icon
} from 'semantic-ui-react';

import { modes } from '../../../utils/common';
import { ReportParamList, PageVisual, ReportInterval, GetReportButtons } from '..';

import './uspd-report-settings.css'

class UspdReportSettings extends Component {

  state = {
    isVisibleSettings: true,
  };

  checkVisibleSettings = () => {
    const { isVisibleSettings } = this.state;
    this.setState({ isVisibleSettings: !isVisibleSettings });
  }

  isDisabled = () => {
    const { regimes, numberUspd } = this.props;
    return regimes.length === 0 || numberUspd === -1;
  };

  render() {
    const { isVisibleSettings } = this.state;
    const { lines, numberUspd, getData,
      regimes, onChangeUspd, setParams,
      funcFrom, funcTo, from, to, loadDisabled, clearSettings } = this.props;

    const regimeSelection = (
      <ReportParamList
        list={modes}
        reportList={regimes}
        nameKey={"uspd"}
        setParams={(newList) => setParams(newList)}
        label="Все режимы"
      />
    )

    const selectRegims = (
      <Form className='select-regims'>
        <Form.Field label='3. Выберите режимы:' />
        {regimeSelection}
      </Form>
    )

    const uspdList = [
      { key: -1, text: '', value: -1 },
      ...lines.map(({ uspdNumber }) => ({
        key: uspdNumber,
        text: uspdNumber,
        value: uspdNumber
      }))
    ];

    const selectGroup = (
      <Form className='select-group'>
        <Form.Select className='select-uspd'
          label='1. Выберите УСПД:'
          placeholder='Выберите УСПД'
          value={numberUspd}
          options={uspdList}
          onChange={(e, { value }) => onChangeUspd(value)}
        />

        <Form.Field label='2. Выберите интервал:' />
        <ReportInterval
          funcFrom={(day) => funcFrom(day)}
          funcTo={(day) => funcTo(day)}
          from={from}
          to={to} />


        <div className='get-button-computer' >
          <GetReportButtons
            loadDisabled={loadDisabled}
            getDisables={this.isDisabled()}
            // getFile={getFile}
            clearSettings={clearSettings}
            getData={getData}
          />
        </div>

      </Form>
    )

    return (
      <Segment className='uspd-report-settings'>
        <Accordion fluid >
          <Accordion.Title
            active={isVisibleSettings === true}
            onClick={this.checkVisibleSettings}
          >
            <Icon name='dropdown' />
            {"Настройки"}
          </Accordion.Title>
          <Accordion.Content active={isVisibleSettings} >
            <div className='monitor-setting' style={{ visibility: 'visible' }}>
              <PageVisual
                page='monitor-setting'
                firstColumn={selectGroup}
                secondColumn={selectRegims}
              />
              <div className='get-button-mobile'>
                <GetReportButtons
                  loadDisabled={loadDisabled}
                  getDisables={this.isDisabled()}
                  // getFile={getFile}
                  clearSettings={clearSettings}
                  getData={getData}
                />
              </div>

            </div>
          </Accordion.Content>
        </Accordion>
      </Segment>
    )
  }
}
export default UspdReportSettings;
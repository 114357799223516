import React from "react";

import { Segment, List, Header, Image } from 'semantic-ui-react';

import './report-header.css';

const ReportHeader = ({ params }) => {
  return (
    <div className='report-header' >
      <Header as='h4' textAlign='center'>Параметры отчета</Header>
      <Segment className='param-select'>
        <List>
          {Object.keys(params).map(param => (
            <List.Item key={param}>
              <List.Content>
                <span className="tag">{param}</span>
                {params[param]}
              </List.Content>
            </List.Item>

            // <List.Item key={param}><span className="tag">{param}</span>
            //   {params[param]}
            // </List.Item>
          ))}
        </List>
      </Segment>
    </div>
  )
}
export default ReportHeader;
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setTypeReport } from '../../../redux/actions';
import { createList, getInterval } from '../../../utils/common';

import { Segment } from 'semantic-ui-react';
import { ReportHeader } from '../../views';
import ReportDataContainer from '../../containers/report-data-container';

import * as areasReducer from '../../../redux/reducers/reduce-areas';
import * as factoryReducer from '../../../redux/reducers/reduce-factorys';
import * as reportReducer from '../../../redux/reducers/reduce-report';


import './production-report.css';

class ProductionReport extends Component {

  componentWillUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.props.setTypeReport(prevProps.type)
    }
  }

  createParamsList = () => {
    const { areas, reportAreas, factoryTypes, reportTypes, sortingBy, from, to } = this.props;
    const params = {
      "Выбранныее регионы: ": createList(areas, reportAreas, 'Все регионы'),
      "Выбранные типы предприятий: ": createList(factoryTypes, reportTypes, 'Все типы предприятий'),
      "Сортировка по: ": sortingBy ? sortingBy.text : <div />,
      "Период отчета: ": getInterval(from, to)
    }
    return params;
  }

  render() {
    return (
      <Segment className='production-report'>
        <ReportHeader params={this.createParamsList()} />
        <ReportDataContainer />
      </Segment>
    )
  }
}

const mapStateToProps = state => {
  const { from, to } = reportReducer.getReportInterval(state);
  return {
    from, to,
    areas: areasReducer.getAreas(state),
    reportAreas: reportReducer.getReportAreas(state),
    factoryTypes: factoryReducer.getFactorysTypes(state),
    reportTypes: reportReducer.getReportTypes(state),
    sortingBy: reportReducer.getReportSortingType(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTypeReport: type => dispatch(setTypeReport(type)),
  }
}

export default
  connect(
    mapStateToProps, 
    mapDispatchToProps
  )(ProductionReport);
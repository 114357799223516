import React, { createRef, Component } from 'react';
import { Popup } from 'semantic-ui-react';

class PopupMessage extends Component {
    state = {};
    contextRef = createRef();

    render() {
        const { children, content, side } = this.props;
        return (
            <React.Fragment>
                <Popup
                    trigger={children}
                    content={content}
                    position={`${side ? side : 'top'} center`}
                    inverted
                />
            </React.Fragment>
        );
    }
}

export default PopupMessage;
